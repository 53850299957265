import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-self-vistoria-aprovado-component',
  templateUrl: './self-vistoria-aprovado.component.html',
  styleUrls: ['./self-vistoria-aprovado.component.css'],
})

export class SelfVistoriaAprovadoComponent {
  companyLogo = environment.company_logo;
  aprovadoIcon = environment.aprovado_icon;
  aprovadoText = environment.aprovado_text;
  aprovadoTitle = environment.aprovado_title;

  constructor(
  ) {
  }
}
