import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-unauthorized-component',
  templateUrl: './unauthorized.component.html',
  styleUrls: ['./unauthorized.component.css'],
})

export class UnauthorizedComponent {
  companyLogo = environment.company_logo;
  falhaIcon = environment.falha_icon;
  falhaText = environment.falha_text;
  falhaTitle = environment.falha_title;
}
