<mat-dialog-content>
    <span class="termos-span">
        <p>{{ data.text }}</p>
    </span>
</mat-dialog-content>
<div *ngIf="!data.disableClose"
     mat-dialog-actions
     class="actions"
     (click)="fecharAlert()">
  <button class="btn-1"
          data-dd-action-name="Entendeu e fechou o modal de alerta"
          mat-button>
    Entendi
  </button>
</div>
