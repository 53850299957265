import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProposalService } from '../../../shared/services/proposal.service';
import { UtilErrors } from '../../../shared';
import { AlertService } from '../../../shared/services/alert.service';

@Injectable()
export class HasAllPhotosGuard implements CanActivate {

  constructor(
      private _proposalService: ProposalService,
      private _router: Router,
      private _alertService: AlertService,
  ) {
  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    try {
      const proposal = this._proposalService.getProposal();

      if (!proposal) {
        return this._router.parseUrl('/falha');
      }

      const hasAllPhotos = proposal.pictures.every((picture) => {
        return picture.takenPicture;
      });

      if (!hasAllPhotos) {
        return true;
      }

      return this._router.parseUrl('/self/documentos');
    } catch (error) {
      this._alertService.showAlert(UtilErrors.UNKNOWN);
      return this._router.parseUrl('/falha');
    }
  }

}
