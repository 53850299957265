import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginModule } from './components/login/login.module';
import {
  ResetPasswordModule,
} from './components/reset-password/reset-password.module';
import { SelfModule } from './components/self/self.module';
import { MatIconModule } from '@angular/material/icon';
import { ProposalService } from './shared/services/proposal.service';
import { PermissionsService } from './shared/services/permissions.service';
import { CameraService } from './shared/services/camera.service';
import { LocalStorageService } from './shared';
import { DatadogService } from './shared/services/datadog.service';
import {
  BrowserNotAllowedComponent,
} from './components/browser-not-allowed/browser-not-allowed.component';
import { SharedModule } from './shared/shared.modules';

@NgModule({
  declarations: [
    AppComponent,
    BrowserNotAllowedComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LoginModule,
    SelfModule,
    ResetPasswordModule,
    BrowserAnimationsModule,
    MatIconModule,
    SharedModule,
  ],
  providers: [
    LocalStorageService,
    CameraService,
    PermissionsService,
    ProposalService,
    DatadogService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
