import {
  ProposalResponseResource,
  ProposalResponseResourceTypeEnum,
} from './proposal-response.model';
import { UtilService } from '../services';
import {
  ProposalResourceShapeGuide,
  ShapeGuideType,
} from './proposal-resource-shape.model';

export class ProposalResource {
  id: string;
  type: string;
  description: string | null;
  fileTypes: string[] = [];
  icon: string | null;
  layout: string | null;
  takenPicture: string | null;
  isPending: boolean;
  fileExtension: string | null;
  sentIcon: string | null;
  pendencyReasons: string | null;
  pendencyReason: string | null;

  // custom properties to improve code readability
  shapeGuide: ProposalResourceShapeGuide;
  isDocument: boolean = false;
  fileType: 'image' | 'document' | '' = '';

  constructor(
      responseResource: ProposalResponseResource,
      isProposalPending = false,
  ) {
    this.id = responseResource.id;
    this.type = responseResource.tipo;
    this.description = responseResource.descricao;
    this.fileTypes = responseResource.tipo_arquivo_aceitos;
    this.icon = responseResource.icone;
    this.layout = responseResource.layout;
    this.takenPicture = (isProposalPending && responseResource.pendente)
                        ? ''
                        : responseResource.fotoTirada;
    this.isPending = responseResource.pendente;
    this.fileExtension = responseResource.tipoArquivo;
    this.sentIcon = responseResource.iconeEnviada;
    this.pendencyReasons = responseResource.motivosPendencia;
    this.pendencyReason = responseResource.motivoPendencia;

    this.shapeGuide = ProposalResource.getShapeGuide(
        UtilService.normalizeString(responseResource.descricao),
    );
    this.isDocument = this.type === ProposalResponseResourceTypeEnum.documents;
    this.fileType = UtilService.getFileType(responseResource.tipoArquivo);
  }

  static getResourceShapeGuides() {
    return {
      chassi: new ProposalResourceShapeGuide(
          ShapeGuideType.CHASSI,
          `Fotografe o número do chassi que fica na parte interna do carro (17 dígitos). Não aceitamos fotos do vidro.`,
      ),
      sides: {
        driver: {
          front: new ProposalResourceShapeGuide(ShapeGuideType.DRIVER_FRONT),
          rear: new ProposalResourceShapeGuide(ShapeGuideType.DRIVER_REAR),
        },
        passenger: {
          front: new ProposalResourceShapeGuide(ShapeGuideType.PASSENGER_FRONT),
          rear: new ProposalResourceShapeGuide(ShapeGuideType.PASSENGER_REAR),
        },
      },
      panel: new ProposalResourceShapeGuide(
          ShapeGuideType.PANEL,
          `Fotografe o painel com o motor em funcionamento.`,
      ),
      licenses: {
        vehicle: new ProposalResourceShapeGuide(ShapeGuideType.VEHICLE_LICENSE),
        driver: new ProposalResourceShapeGuide(ShapeGuideType.DRIVER_LICENSE),
      },
    };
  }

  static getShapeGuide(description: string | null): ProposalResourceShapeGuide {

    const defaultShapeGuide = {
      portrait: '',
      landscape: '',
      description: '',
    };

    if (!description) {
      return defaultShapeGuide;
    }

    const resourceShapeGuides = ProposalResource.getResourceShapeGuides();

    const descriptionLower = description.toLowerCase();

    const isFront = descriptionLower.includes('frente')
                    || descriptionLower.includes('front');

    const isRear = descriptionLower.includes('traseir');

    const isVehicleLicense = descriptionLower.includes('licenciamento')
                             || descriptionLower.includes('documento do veiculo');

    const isDriverLicense = descriptionLower.includes('cnh')
                            || descriptionLower.includes('documento do segurado');

    const isPanel = descriptionLower.includes('odometro')
                    || descriptionLower.includes('painel');

    if (descriptionLower.includes('chassi')) {
      return resourceShapeGuides.chassi;
    }

    if (descriptionLower.includes('lateral')) {
      if (descriptionLower.includes('motorista')) {
        if (isFront) {
          return resourceShapeGuides.sides.driver.front;
        }
        if (isRear) {
          return resourceShapeGuides.sides.driver.rear;
        }
      }

      if (descriptionLower.includes('passageiro')) {
        if (isFront) {
          return resourceShapeGuides.sides.passenger.front;
        }
        if (isRear) {
          return resourceShapeGuides.sides.passenger.rear;
        }
      }
    }

    if (isPanel) {
      return resourceShapeGuides.panel;
    }

    if (isVehicleLicense) {
      return resourceShapeGuides.licenses.vehicle;
    }

    if (isDriverLicense) {
      return resourceShapeGuides.licenses.driver;
    }

    return defaultShapeGuide;
  }
}
