import { Injectable } from '@angular/core';
import { ProposalResource } from '../models/proposal-resource.model';
import { ProposalModel } from '../models/proposal.model';
import { LocalStorageService } from './local-storage.service';
import {
  ProposalResponseQuestionStepEnum,
} from '../models/proposal-response.model';
import { ProposalQuestionModel } from '../models/proposal-question.model';

export enum ProposalResourceTypeEnum {
  pictures = 'pictures',
  documents = 'documents',
}

export enum ProposalErrors {
  PROPOSAL_NOT_FOUND = 'Proposal not found in local storage',
}

export type ProposalResourceType = ProposalResourceTypeEnum;

@Injectable()
export class ProposalService {

  proposal: ProposalModel | undefined;

  private _proposalStorageKey = 'proposal';

  constructor(private localStorage: LocalStorageService) {
  }

  setProposal(proposal: ProposalModel): void {
    this.proposal = proposal;
    this.localStorage.set(this._proposalStorageKey, this.proposal);
  }

  getProposal(): ProposalModel {
    this.proposal = this.localStorage.get(this._proposalStorageKey);

    if (!this.proposal) {
      throw new Error(ProposalErrors.PROPOSAL_NOT_FOUND);
    }

    return this.proposal;
  }

  clearProposal() {
    this.localStorage.remove(this._proposalStorageKey);
  }

  updateQuestionInProposal(questionToUpdate: ProposalQuestionModel) {
    const proposal = this.getProposal();

    const updatedQuestions = proposal.questions
                                     .map((question: ProposalQuestionModel) => {

                                       if (question.id
                                           !== questionToUpdate.id) {
                                         return question;
                                       }

                                       return {
                                         ...question,
                                         ...questionToUpdate,
                                       };
                                     });

    // update proposal in local storage
    this.localStorage.set(this._proposalStorageKey, {
      ...proposal,
      questions: updatedQuestions,
    });
  }

  updateResourceInProposal(resourceToUpdate: ProposalResource) {

    const proposal = this.getProposal();
    const type: ProposalResourceType = this._getResourceType(resourceToUpdate);

    const updatedResources = proposal[type]
        .map((resource: ProposalResource) => {

          if (resource.id !== resourceToUpdate.id) {
            return resource;
          }

          return {
            ...resource,
            ...resourceToUpdate,
          };
        });

    // update proposal in local storage
    this.localStorage.set(this._proposalStorageKey, {
      ...proposal,
      [type]: updatedResources,
    });
  }

  getSelfInspectionQuestions(): ProposalQuestionModel[] {
    return this._getProposalQuestions()
               .filter((question) => ProposalResponseQuestionStepEnum.INSPECTION
                                     || !question.step);
  }

  getTrackerInstallationPreQuestions(): ProposalQuestionModel[] {

    return this._getProposalQuestions()
               .filter((question) => question.step
                                     === ProposalResponseQuestionStepEnum.PRE_INSTALLATION);
  }

  getTrackerInstallationPostQuestions(): ProposalQuestionModel[] {

    return this._getProposalQuestions()
               .filter((question) => question.step
                                     === ProposalResponseQuestionStepEnum.POST_INSTALLATION);
  }

  private _getResourceType(resource: ProposalResource): ProposalResourceType {
    if (!resource.isDocument) {
      return ProposalResourceTypeEnum.pictures;
    }

    return ProposalResourceTypeEnum.documents;
  }

  private _getProposalQuestions(): ProposalQuestionModel[] {
    const proposal = this.getProposal();

    if (!proposal.questions || !proposal.questions.length) {
      return [];
    }

    return proposal.questions;
  }
}
