<div class="self-vistoria-background"
     id="">
  <div class="self-vistoria-header-container">
    <div class="self-vistoria-header">
      <div class="self-vistoria-image-header"></div>
      <div class="self-vistoria-text-header">Instalação do rastreador</div>
      <div>
        <img src="{{ companyLogo }}"
             class="company-logo vistoria"/>
      </div>
    </div>

    <div class="container-stepper">
      <label class="label-stepper-separator active"> Dados </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Pré instalação </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Fotos </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Pós instalação </label>
    </div>
  </div>

  <div class="container-self-information">
    <div class="container-self-questionario">
      <div class="container-questionario-header">
        <p class="text-form"><span class="ind">1.</span> Dados da Instalação</p>
      </div>

      <ng-container *ngIf="proposal">
        <div class="layout-container-input">
          <label class="layout-label-overall-label">Nº da proposta</label>
          <p class="text-info">{{ proposal.proposal.id }}</p>
        </div>
        <div class="layout-container-input">
          <label class="layout-label-overall-label">Placa</label>
          <p class="text-info">{{ proposal.proposal.vehicle.plate }}</p>
        </div>
        <div class="layout-container-input">
          <label class="layout-label-overall-label">Chassi</label>
          <p class="text-info">{{ proposal.proposal.vehicle.chassi }}</p>
        </div>
        <div class="layout-container-input">
          <label class="layout-label-overall-label">Modelo</label>
          <p class="text-info">
            {{ proposal.proposal.vehicle.brand }}
            {{ proposal.proposal.vehicle.model }}
          </p>
        </div>
        <div class="layout-container-input">
          <label class="layout-label-overall-label">Ano</label>
          <p class="text-info">{{ proposal.proposal.vehicle.year }}</p>
        </div>
        <div class="layout-container-input">
          <label class="layout-label-overall-label">Nome do Cliente</label>
          <p class="text-info">
            {{ proposal.proposal.insured.nameOrCompanyName }}
          </p>
        </div>
        <div *ngIf="proposal.proposal.insured.socialName" class="layout-container-input">
          <label class="layout-label-overall-label">Nome social</label>
          <p class="text-info">
            {{ proposal.proposal.insured.socialName }}
          </p>
        </div>
        <div class="layout-container-input">
          <label class="layout-label-overall-label">CPF/CNPJ do Cliente</label>
          <p class="text-info">
            {{ proposal.proposal.insured.cpfCnpj }}
          </p>
        </div>
      </ng-container>
    </div>

    <button (click)="submit()"
            class="layout-btn-overall layout-btn-label-overall success">
      Avançar
    </button>
  </div>
</div>

<div class="footer">
  <mat-icon class="material-symbols-outlined"> lock</mat-icon>
  <span>usebens.com.br</span>
</div>
