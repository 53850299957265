import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { SelfService, UtilService } from './shared';
import { catchError, map } from 'rxjs/operators';
import { datadogRum } from '@datadog/browser-rum';
import { AuthTokenService } from './shared/services/auth-token.service';

@Injectable()
export class ProposalGuard implements CanActivate {

  constructor(
      private _authTokenService: AuthTokenService,
      private _router: Router,
      private _selfService: SelfService,
  ) {
  }

  canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const queryParams = route.queryParams;
    const authToken = queryParams['access'] || this._authTokenService.get();

    if (!authToken) {
      return this._router.parseUrl('/falha');
    }

    return this._authorize(authToken);
  }

  private _authorize(authToken: string): Observable<boolean | UrlTree> {

    return this._selfService
               .selfLogin(authToken)
               .pipe(
                   map((response) => {
                     if (response.status === 'Enviada') {
                       return this._router.parseUrl('/sucesso');
                     }

                     if (response.status === 'Aprovada') {
                       return this._router.parseUrl('/aprovado');
                     }

                     if (response.status === 'Recusada') {
                       return this._router.parseUrl('/erro');
                     }

                     return true;
                   }),
                   catchError((err: {
                         enviada: boolean;
                         msg: string;
                         reenvio: boolean
                       }) => {

                      UtilService.log('Error', err);

                         if (!err) {
                           datadogRum.addError(err);
                           return of(this._router.parseUrl('/falha'));
                         }

                         if (err.reenvio) {
                           return of(this._router.parseUrl('/reenvio'));
                         }

                         if (err.enviada) {
                           return of(this._router.parseUrl('/sucesso'));
                         }

                         datadogRum.addError(err);
                         return of(this._router.parseUrl('/falha'));
                       },
                   ),
               );
  }
}
