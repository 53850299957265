import { UploadResultResponseModel } from './upload-result-response.model';

export interface UploadResourceResult {
  url: string;
  id: string;
  version: string;
}

export class UploadResultModel {
  isSuccess: boolean;
  message: string;
  result: UploadResourceResult;
  fileType: string;
  status: string;

  constructor(resultResponse: UploadResultResponseModel) {
    this.isSuccess = resultResponse.sucesso;
    this.message = resultResponse.msg || '';
    this.result = {
      url: resultResponse.anexoResultado.url,
      id: resultResponse.anexoResultado.id,
      version: resultResponse.anexoResultado.versao,
    };
    this.fileType = resultResponse.tipoArquivo;
    this.status = resultResponse.status;
  }
}
