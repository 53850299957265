import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UtilErrors, UtilService } from 'src/app/shared';
import { environment } from '../../../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { ProposalModel } from '../../../shared/models/proposal.model';
import { ProposalService } from '../../../shared/services/proposal.service';
import { MatRadioChange } from '@angular/material/radio';
import {
  ProposalQuestionModel,
} from '../../../shared/models/proposal-question.model';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-tracker-checklist-pre',
  templateUrl: './tracker-checklist-pre.component.html',
  styleUrls: ['./tracker-checklist-pre.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerChecklistPreComponent implements OnInit {

  companyLogo = environment.company_logo;

  proposal: ProposalModel | undefined;
  questions: ProposalQuestionModel[] = [];

  constructor(
      public utilService: UtilService,
      private _proposalService: ProposalService,
      private _alertsService: AlertService,
      private router: Router,
  ) {
  }

  ngOnInit() {

    try {
      this.proposal = this._proposalService.getProposal();
      this.questions = this._proposalService.getTrackerInstallationPreQuestions();
      this.canSubmitChecklist();
    } catch (e) {
      this._alertsService.showAlert(UtilErrors.UNKNOWN);
    }
  }

  canSubmitChecklist(): boolean {

    if (!this.proposal) {
      datadogRum.addError(`Proposal not found in ${ TrackerChecklistPreComponent.name } ${ this.canSubmitChecklist.name } method`);
      return false;
    }

    return this._proposalService
               .getTrackerInstallationPreQuestions()
               .every(UtilService.isQuestionAnswered);
  }

  onQuestionChange(event: MatRadioChange, question: ProposalQuestionModel) {
    this._proposalService
        .updateQuestionInProposal({
          ...question,
          answer: event.value ? event.value : '',
        } as ProposalQuestionModel);

    this._updateProposalFromStorage();
    this.canSubmitChecklist();
  }

  onInputChange(event: Event, question: ProposalQuestionModel) {

    if (!event.target) {
      throw new Error('Event target not found');
    }

    const element = event.target as HTMLInputElement;

    this._proposalService
        .updateQuestionInProposal({
          ...question,
          answer: element.value ? element.value : '',
        } as ProposalQuestionModel);

    this._updateProposalFromStorage();
    this.canSubmitChecklist();
  }

  submit() {

    if (!this.proposal) {
      datadogRum.addError(`Proposal not found in ${ TrackerChecklistPreComponent.name } ${ this.submit.name } method`);
      return;
    }

    this._proposalService.setProposal(this.proposal);

    this.router.navigateByUrl('/self/foto-tracker');
  }

  private _updateProposalFromStorage() {
    this.proposal = this._proposalService.getProposal();
  }
}
