import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelfComponent } from './self.component';
import { SelfFotoComponent } from './self-fotos/self-foto.component';
import {
  SelfDocumentosComponent,
} from './self-documentos/self-documentos.component';
import { NeedsAllPhotosGuard } from './self-documentos/needs-all-photos.guard';
import { HasAllPhotosGuard } from './self-fotos/has-all-photos.guard';
import {
  TrackerQuestionarioComponent,
} from './tracker-questionario/tracker-questionario.component';
import {
  TrackerChecklistPreComponent,
} from './tracker-checklist-pre/tracker-checklist-pre.component';
import {
  TrackerSuccessComponent,
} from './self-rastreador-sucesso/tracker-success.component';
import { TrackerFotoComponent } from './tracker-fotos/tracker-foto.component';
import {
  TrackerChecklistPosComponent,
} from './tracker-checklist-pos/tracker-checklist-pos.component';
import { ProposalGuard } from '../../proposal-guard.service';

const selfRoutes: Routes = [
  {
    path: '',
    component: SelfComponent,
    canActivate: [ProposalGuard],
    children: [
      {
        path: 'foto-veiculo',
        canActivate: [HasAllPhotosGuard],
        component: SelfFotoComponent,
      },
      {
        path: 'documentos',
        canActivate: [NeedsAllPhotosGuard],
        component: SelfDocumentosComponent,
      },
      {
        path: 'questionario-tracker',
        component: TrackerQuestionarioComponent,
      },
      {
        path: 'checklist-tracker-pre',
        component: TrackerChecklistPreComponent,
      },
      {
        path: 'foto-tracker',
        component: TrackerFotoComponent,
      },
      {
        path: 'checklist-tracker-pos',
        component: TrackerChecklistPosComponent,
      },
      {
        path: 'sucesso-tracker',
        component: TrackerSuccessComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(selfRoutes),
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    ProposalGuard,
    HasAllPhotosGuard,
    NeedsAllPhotosGuard,
  ],
})
export class SelfRoutingModule {}
