import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthTokenService } from './auth-token.service';
import { UtilService } from './util.service';

@Injectable()
export class ApiService {

  constructor(
      private http: HttpClient,
      private _authTokenService: AuthTokenService,
  ) {
  }

  private formatErrors(error: any) {

    if (error.message) {
      UtilService.log('Error', error.message);
    }

    return throwError(error.error);
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    return this.http.get(
                   `${ environment.api_url }${ path }`,
                   { params, headers: this.header() },
               )
               .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
        `${ environment.api_url }${ path }`,
        body, { headers: this.header() },
    ).pipe(catchError(this.formatErrors));
  }

  post(options: {
    path: string,
    body?: any,
    headers?: HttpHeaders
  }): Observable<any> {
    return this.http
               .post(
                   `${ environment.api_url }${ options.path }`,
                   { ...options.body },
                   { headers: options.headers || this.header() },
               )
               .pipe(catchError(this.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(
        `${ environment.api_url }${ path }`, { headers: this.header() },
    ).pipe(catchError(this.formatErrors));
  }

  header(): HttpHeaders {

    const token = this._authTokenService.get();

    if (token) {
      return new HttpHeaders().set('Authorization', 'Bearer ' + token);
    }

    return new HttpHeaders();
  }
}
