<mat-nav-list>

  <h2>
    Como deseja enviar a foto?
  </h2>

  <a (click)="takePicture()"
     mat-list-item>
    <ion-icon name="camera"></ion-icon>
    <span mat-line>Câmera</span>
  </a>

  <a (click)="selectFile()"
     mat-list-item>
    <ion-icon name="document"></ion-icon>
    <span mat-line>Galeria</span>
  </a>
</mat-nav-list>
