import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { UtilErrors, UtilService } from 'src/app/shared';
import { environment } from '../../../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { PlayerComponent } from '../../player/player.component';
import {
  PermissionsService,
} from '../../../shared/services/permissions.service';
import { ProposalService } from '../../../shared/services/proposal.service';
import { ProposalModel } from '../../../shared/models/proposal.model';
import {
  ProposalResource,
} from '../../../shared/models/proposal-resource.model';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-self-foto-component',
  templateUrl: './tracker-foto.component.html',
  styleUrls: ['./tracker-foto.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TrackerFotoComponent implements OnInit {

  @ViewChild(PlayerComponent)
  playerComponent: PlayerComponent | undefined;

  companyLogo = environment.company_logo;
  errorMessage: string = '';
  proposal: ProposalModel | undefined;

  constructor(
      private _router: Router,
      private _permissionsService: PermissionsService,
      private _proposalService: ProposalService,
      private _alertsService: AlertService,
      public utilService: UtilService,
  ) {
  }

  async ngOnInit() {
    try {
      this.proposal = this._proposalService.getProposal();
      this.canSubmitPictures();
    } catch (e) {
      this._alertsService.showAlert(UtilErrors.UNKNOWN);
    }
  }

  canSubmitPictures() {
    try {
      return this._proposalService
                 .getProposal()
                 .pictures
                 .every((picture) => !!picture.takenPicture);
    } catch (erro) {
      UtilService.log(erro);
      return false;
    }
  }

  // needs to call this method before takePicture to ensure user gesture.
  async getOrientationPermission(picture: ProposalResource) {
    await this._permissionsService.getOrientationPermission();
    this.takePicture(picture);
  }

  async takePicture(picture: ProposalResource) {

    if (!this._permissionsService.getProjectPermissions().camera) {
      try {
        await this._permissionsService.getCameraPermission();
        await this._startCamera(picture);
      } catch (e) {
        this._alertsService.showAlert(
            `Para sua segurança e privacidade, não podemos seguir, com a
            permissão de camera negada! Por favor, permita os acessos
            manualmente e recarregue a página.`,
            true,
        );
      }
      return;
    }

    try {
      await this._startCamera(picture);
    } catch (erro) {
      datadogRum.addError(erro);
    }
  }

  onCapture($event: string) {
    this.canSubmitPictures();
    this._updateProposalFromStorage();
  }

  submit() {
    try {

      if (!this.canSubmitPictures()) {
        this.errorMessage = 'Para continuar, envie todas as fotos solicitadas.';
        return;
      }

      this._router.navigateByUrl('/self/checklist-tracker-pos');
    } catch (erro) {
      datadogRum.addError(erro);
    }
  }

  private async _startCamera(picture: ProposalResource): Promise<void> {

    this._checkPicturePendency(picture)
        .subscribe({
          next: async () => {
            if (!this.playerComponent) {
              return;
            }

            await this.playerComponent.startCamera(picture);
          },
        });
  }

  private _checkPicturePendency(picture: ProposalResource) {
    const hasPicturePendency = picture.isPending && !!picture.pendencyReason;

    if (!hasPicturePendency) {
      return of(false);
    }

    return this._alertsService
               .showAlert(picture.pendencyReason)
               .afterClosed();
  }

  private _updateProposalFromStorage() {
    this.proposal = this._proposalService.getProposal();
  }
}
