import { Injectable } from '@angular/core';
import { UtilService } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class CanvasService {

  private _canvas = document.createElement('canvas');
  private _context = this._canvas.getContext('2d');
  private _player: HTMLVideoElement | undefined;
  private _deviceOrientation: OrientationType | undefined;
  private _originalImage: string = '';
  private _isListeningToDeviceOrientation = false;

  constructor() {
  }

  drawOnCanvas(player: HTMLVideoElement) {

    this._player = player;

    if (!this._context) {
      throw new Error('Canvas context not found');
    }

    UtilService.log(`Screen orientation: ${ (screen.orientation)
                                            ? screen.orientation.type
                                            : 'Not supported' }`);
    UtilService.log(`Device Orientation: ${ this._deviceOrientation }`);

    const videoWidth = player.videoWidth;
    const videoHeight = player.videoHeight;

    this._setOriginalImage(videoWidth, videoHeight, player);

    this._canvas.width = videoWidth;
    this._canvas.height = videoHeight;

    if (this._isScreenOnLandscape()) {
      this._context.clearRect(0, 0, this._canvas.width, this._canvas.height);
      this._context.drawImage(player, 0, 0);
      return;
    }

    // screen is on portrait

    if (!this._deviceOrientation || this._deviceOrientation.includes('portrait')) {
      this._context.clearRect(0, 0, this._canvas.width, this._canvas.height);
      this._context.drawImage(player, 0, 0, this._canvas.width, this._canvas.height);
      return;
    }

    this._canvas.width = videoHeight;
    this._canvas.height = videoWidth;

    if (this._isDeviceLandscapePrimary()) {
      this._drawDeviceLandscapePrimary();
      return;
    }

    if (this._isDeviceLandscapeSecondary()) {
      this._drawDeviceLandscapeSecondary();
      return;
    }
  }

  getImageFromCanvas(): string {
    return this._canvas.toDataURL('image/jpeg');
  }

  getOriginalImage(): string {
    return this._originalImage;
  }

  listenToDeviceOrientationChange() {

    // https://developer.mozilla.org/en-US/docs/Web/API/Device_orientation_events/Orientation_and_motion_data_explained

    const deadZone = 40;

    if (this._isListeningToDeviceOrientation) {
      return;
    }

    if (!window.DeviceOrientationEvent) {
      console.log('Your device does not support DeviceOrientationEvent API.');
      return;
    }

    window.addEventListener(
        'deviceorientation',
        (event: DeviceOrientationEvent) => {

          this._isListeningToDeviceOrientation = true;
          const leftToRight = event.gamma; // gamma: left to right

          if (!leftToRight) {
            this._deviceOrientation = 'portrait-primary';
            return;
          }

          if (leftToRight < -deadZone) { // negativo
            this._deviceOrientation = 'landscape-primary';
            return;
          }

          if (leftToRight > deadZone) { // positivo
            this._deviceOrientation = 'landscape-secondary';
            return;
          }

          this._deviceOrientation = 'portrait-primary';
        },
    );
  }

  private _setOriginalImage(
      videoWidth: number,
      videoHeight: number,
      player: HTMLVideoElement,
  ) {
    if (!this._context) {
      throw new Error('Canvas context not found');
    }

    this._canvas.width = videoWidth;
    this._canvas.height = videoHeight;
    this._context.drawImage(
        player,
        0,
        0,
        player.videoWidth,
        player.videoHeight,
    );
    this._originalImage = this.getImageFromCanvas();
    this._context.clearRect(0, 0, this._canvas.width, this._canvas.height);
  }

  private _isScreenOnLandscape() {
    return (screen.orientation)
           ? screen.orientation.type.includes('landscape')
           : false;
  }

  private _isDeviceLandscapePrimary() {
    return this._deviceOrientation === 'landscape-primary';
  }

  private _isDeviceLandscapeSecondary() {
    return this._deviceOrientation === 'landscape-secondary';
  }

  private _drawDeviceLandscapePrimary() {
    // 270° rotate right
    this._drawScalledImage(270);
  };

  private _drawDeviceLandscapeSecondary() {
    // 90° rotate right
    this._drawScalledImage(90);
  };

  private _drawScalledImage(rotation: number) {

    if (!this._context) {
      throw new Error('Canvas context not found');
    }

    if (!this._player) {
      throw new Error('Player not found');
    }

    const newCanvasWidth = this._canvas.height;
    const newCanvasHeight = this._canvas.width;
    const drawX = -newCanvasWidth / 2;
    const drawY = -newCanvasHeight / 2;

    this._context.save();
    this._context.clearRect(0, 0, newCanvasWidth, newCanvasHeight);
    this._context.translate(this._canvas.width / 2, this._canvas.height / 2);
    this._context.rotate(rotation * Math.PI / 180);
    this._context.drawImage(
        this._player,
        0,
        0,
        this._player.videoWidth,
        this._player.videoHeight,
        drawX,
        drawY,
        newCanvasWidth,
        newCanvasHeight,
    );
    this._context.restore();
  }

}
