import {
  ProposalResponseQuestion,
  ProposalResponseQuestionStepEnum,
} from './proposal-response.model';

export class ProposalQuestionModel {
  id: number;
  description: string;
  key: string;
  dataType: string;
  answer: string | null;
  preAnswers: string[];
  step: ProposalResponseQuestionStepEnum;
  required: boolean;

  constructor(responseQuestion: ProposalResponseQuestion) {

    this.id = responseQuestion.id;
    this.description = responseQuestion.descricao;
    this.key = responseQuestion.chave;
    this.dataType = responseQuestion.tipo_dado;
    this.answer = responseQuestion.resposta;
    this.preAnswers = (responseQuestion.pre_respostas)
                      ? JSON.parse(responseQuestion.pre_respostas)
                      : [];
    this.step = (responseQuestion.etapa)
                ? responseQuestion.etapa
                : ProposalResponseQuestionStepEnum.INSPECTION;
    this.required = this._isRequired(responseQuestion);
  }

  private _isRequired(responseQuestion: ProposalResponseQuestion): boolean {
    return responseQuestion.tipo_dado !== 'TEXT_AREA';
  }
}
