import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { NgxCheckPermissionService } from 'ngx-check-permission';
import { CameraService } from './camera.service';
import { UtilService } from './util.service';
import { LocalStorageService } from './local-storage.service';
import { DatadogService } from './datadog.service';
import { CanvasService } from '../../components/player/canvas.service';

const GEOLOCATION_PERMISSION_KEY = 'geolocation';
const ORIENTATION_PERMISSION_KEY = 'orientation';

export type PermissionName = 'camera' | 'geolocation';
export type PermissionState = 'granted' | 'denied' | 'prompt';

export type Permission = {
  permissionName: PermissionName;
  errorMessage: string;
  state: PermissionState;
};

export type ProjectPermissions = {
  camera: boolean;
  geolocation: boolean;
};

export type GeoPosition = {
  longitude: number;
  latitude: number;
};

@Injectable()
export class PermissionsService {

  private _hasPermissions = false;
  private _projectPermissions: ProjectPermissions = {
    camera: false,
    geolocation: false,
  };
  private _geoPosition: GeoPosition | undefined;

  constructor(
      private _permissionService: NgxCheckPermissionService,
      private _cameraService: CameraService,
      private _localStorageService: LocalStorageService,
      private _dataDogService: DatadogService,
      private _canvasService: CanvasService,
  ) {
    this.checkPermissions()
        .then((result) => {
          UtilService.log(`Initial status: has all permissions granted?: ${ result }`);
        });
  }

  async checkPermissions(): Promise<boolean> {
    try {
      const permissions: Permission[] = await this._permissionService.getAllPermissions();

      if (!permissions.length) {
        this._hasPermissions = false;
        return false;
      }

      const filteredPermissions = permissions
          .filter((permission) => {
            const isCamera = permission.permissionName === 'camera';
            const isGeolocation = permission.permissionName
                                  === 'geolocation';

            this._projectPermissions[permission.permissionName] = permission.state
                                                                  === 'granted';

            if (isGeolocation) {
              const geolocationPermission = this._localStorageService.get(
                  GEOLOCATION_PERMISSION_KEY);

              if (!geolocationPermission && permission.state !== 'denied') {
                this._localStorageService.set(GEOLOCATION_PERMISSION_KEY, true);
              }

              if (geolocationPermission) {
                permission.state = geolocationPermission;
              }
            }

            return isCamera || isGeolocation;
          });

      if (!filteredPermissions.length) {
        this._hasPermissions = false;
        return false;
      }

      const needsPermission = filteredPermissions
          .some((permission) =>
              permission.state === 'denied' || permission.state === 'prompt');

      this._hasPermissions = !needsPermission;
      return !needsPermission;
    } catch (erro) {
      datadogRum.addError(erro);
      throw erro;
    }
  }

  async getCameraPermission(): Promise<void> {
    // call camera to force permission request
    const stream = await this._cameraService.getCameraStream();
    await this.checkPermissions();

    const permission = this._projectPermissions.camera;
    UtilService.log(`After asked camera permission status: ${ permission }`);
    this._dataDogService.updateDatadogUser('camera_permission', permission);

    // stop camera tracks to avoid memory leaks
    return stream.getTracks()
                 .forEach((track) => track.stop());
  }

  getLocationService(): Promise<GeoPosition> {
    return new Promise((resolve, reject) => {
      navigator.geolocation
               .getCurrentPosition(
                   (resp) => {
                     // Saves permission in storage because iOS always return
                     // 'prompt' unless the user deny multiple times.
                     this._localStorageService.set(
                         GEOLOCATION_PERMISSION_KEY,
                         'granted',
                     );
                     this._geoPosition = {
                       longitude: resp.coords.longitude,
                       latitude: resp.coords.latitude,
                     };
                     this._dataDogService.updateDatadogUser(
                         'geolocation_permission',
                         true,
                     );
                     this._dataDogService.updateDatadogUser(
                         'latitude',
                         this._geoPosition.latitude,
                     );
                     this._dataDogService.updateDatadogUser(
                         'longitude',
                         this._geoPosition.latitude,
                     );
                     resolve(this._geoPosition);
                   },
                   (resp) => {
                     this._localStorageService.remove(GEOLOCATION_PERMISSION_KEY);
                     reject({ err: resp.code });
                   },
               );
    });
  }

  async getOrientationPermission(): Promise<PermissionState> {

    if (!UtilService.isIOS()) {
      this._dataDogService.updateDatadogUser('orientation_permission', true);
      this._canvasService.listenToDeviceOrientationChange();
      return Promise.resolve('granted');
    }

    const permissionState = await DeviceOrientationEvent.requestPermission();

    this._localStorageService.set(ORIENTATION_PERMISSION_KEY, permissionState);

    const isGranted = permissionState === 'granted';

    if (isGranted) {
      this._canvasService.listenToDeviceOrientationChange();
    }

    this._dataDogService.updateDatadogUser('orientation_permission', isGranted);

    return permissionState;
  }

  getProjectPermissions(): ProjectPermissions {
    return this._projectPermissions;
  }

  getGeoPosition(): GeoPosition {

    if (!this._geoPosition) {
      throw new Error('Geo position not found');
    }

    return this._geoPosition;
  }

  hasPermissions(): boolean {
    return this._hasPermissions;
  }
}
