export const environment = {
  production: false,
  useMock: false,
  api_url: 'https://vistoria-api-staging.usebens.com.br',
  //api_url: 'https://vistoriaapihml.usebensseguradora.com.br',
  //api_url: 'https://localhost:44308',
  company_logo: '../assets/img/logo-nu.png',
  pdf_icon: '../assets/img/icon-pdf-nu.png',
  car_doc: '../assets/img/car-doc.png',
  client_doc: '../assets/img/client-doc.png',
  car_doc_d: '../assets/img/carinsurance-d.png',
  client_doc_d: '../assets/img/drivinglicense-d.png',
  DDservice: '',
  DDsite: '',
  DDapplicationId: '',
  DDclientToken: '',

  //SUCCESS COMPONENT
  success_icon: '../assets/svg/success.svg',
  success_text:
    'Agora a gente tem até 48h para conferir as informações, mas as coberturas contratadas já estão ativas. A gente acredita num seguro assim, que te dá cobertura sempre.',
  success_title: 'Vistoria enviada com sucesso',

  //FALHA COMPONENT
  falha_icon:
    'https://vistoria-staging-storage.s3.sa-east-1.amazonaws.com/icones/nu/done-process.png',
  falha_text: 'Não foi possível encontrar o que você procura.',
  falha_title: 'Ih, parece que pegamos a rota errada',

  //ERRO COMPONENT
  erro_icon: '../assets/svg/fail.svg',
  erro_text:
    'Não foi possível finalizar sua contratação. Para mais informações, acesse o app do Nubank.',
  erro_title: 'Não conseguimos cobrir seu carro agora',

  //APROVADO COMPONENT
  aprovado_icon: '../assets/svg/approved.svg',
  aprovado_title: 'Seguro aprovado! Aproveite suas proteções',
  aprovado_text:
    'Boas notícias: Seu seguro já está ativo, confira o app do Nubank para mais informações',

  observe: false,
};
