import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-browser-not-allowed',
  templateUrl: './browser-not-allowed.component.html',
  styleUrls: ['./browser-not-allowed.component.scss']
})
export class BrowserNotAllowedComponent implements OnInit {

  logo = environment.company_logo;
  icon = environment.falha_icon;
  title = environment.falha_title;

  constructor() { }

  ngOnInit(): void {
  }

}
