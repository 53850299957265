import { ProposalModel } from './proposal.model';
import { ProposalResponseQuestion } from './proposal-response.model';
import { ProposalQuestionModel } from './proposal-question.model';

export class FinishInspectionPayloadModel {

  vistoria_id: string;
  questionarios: ProposalResponseQuestion[] = [];

  constructor(proposal: ProposalModel) {
    this.vistoria_id = proposal.id;
    this._setQuestions(proposal.questions);
  }

  private _setQuestions(questions: ProposalQuestionModel[]) {
    this.questionarios = questions.map((question: ProposalQuestionModel) => {
      return {
        id: question.id,
        chave: question.key,
        tipo_dado: question.dataType,
        descricao: question.description,
        pre_respostas: question.preAnswers.join(''),
        resposta: question.answer,
        etapa: question.step,
      };
    });
  }
}
