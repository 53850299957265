import { Component, Inject } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss'],
})
export class BottomSheetComponent {

  constructor(
      private _bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: {
        takePicture: () => void,
        selectFile: () => void
      },
  ) {
  }

  takePicture() {
    this.data.takePicture();
    this._bottomSheetRef.dismiss();
  }

  selectFile() {
    this.data.selectFile();
    this._bottomSheetRef.dismiss();
  }
}
