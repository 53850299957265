import { Injectable } from '@angular/core';
import { catchError, map, tap } from 'rxjs/operators';
import { ApiService } from './api.service';
import { HttpHeaders } from '@angular/common/http';
import { AuthTokenService } from './auth-token.service';
import { environment } from '../../../environments/environment';
import {
  UploadFilePayload,
} from '../../components/self/models/upload-file-payload';
import { ProposalResponseModel } from '../models/proposal-response.model';
import { ProposalModel, ProposalProductEnum } from '../models/proposal.model';
import { Observable } from 'rxjs';
import { UploadResultModel } from '../models/upload-result.model';
import {
  UploadResultResponseModel,
} from '../models/upload-result-response.model';
import { ProposalResource } from '../models/proposal-resource.model';
import { PermissionsService } from './permissions.service';
import { ProposalService } from './proposal.service';
import { SpinnerService } from './spinner.service';
import { LocalStorageService } from './local-storage.service';
import { DatadogService } from './datadog.service';
import {
  FinishInspectionPayloadModel,
} from '../models/finish-inspection-payload.model';
import {
  DatadogInspectionAction,
  DatadogResourceAction,
} from '../models/datadog.model';
import {
  FinishInspectionResponseModel,
} from '../models/finish-inspection-response.model';

@Injectable()
export class SelfService {

  constructor(
      private apiService: ApiService,
      private _spinnerService: SpinnerService,
      private _authTokenService: AuthTokenService,
      private _permissionsService: PermissionsService,
      private _proposalService: ProposalService,
      private _localStorage: LocalStorageService,
      private _dataDogService: DatadogService,
  ) {
  }

  selfLogin(authToken: string): Observable<ProposalModel> {

    this._spinnerService.show();

    return this._requestAuth(authToken)
               .pipe(
                   tap(() => {
                     this._authTokenService.set(authToken);
                     this._spinnerService.hide();
                   }),
                   map((response: ProposalResponseModel) => {

                     if (response.status && response.status === 'Expirada') {
                        throw 'Expired Inspection';
                     }

                     return new ProposalModel(response);
                   }),
                   tap((proposal) => {
                     this._proposalService.setProposal(proposal);
                     this._dataDogService.setDatadogUser();

                     if (proposal.proposal.product.id
                         === ProposalProductEnum.TRACKER) {
                       this._localStorage.set('rastreador', true);
                     }
                   }),
                   catchError((err) => {
                     this._errorHandler(err);
                     this._localStorage.clear();
                     throw err;
                   }),
               );
  }

  uploadFile(resource: ProposalResource): Observable<UploadResultModel> {

    if (!resource.takenPicture) {
      throw new Error('Picture was not taken');
    }

    const uploadPayload: UploadFilePayload = {
      id: resource.id,
      anexo: resource.takenPicture.replace('data:image/jpeg;base64,', ''),
      lat: String(this._permissionsService.getGeoPosition().latitude),
      lon: String(this._permissionsService.getGeoPosition().longitude),
      tipoArquivo: resource.fileExtension || 'jpeg',
      pendente: false,
      isDocumento: resource.isDocument,
    };

    this._spinnerService.show();

    return this._requestUploadFile(uploadPayload)
               .pipe(
                   map((resultResponse: UploadResultResponseModel) => {
                     return new UploadResultModel(resultResponse);
                   }),
                   tap((response: UploadResultModel) => {

                     this._spinnerService.hide();

                     if (!response.isSuccess) {
                       throw new Error('An error occurred while uploading');
                     }

                     this._proposalService
                         .updateResourceInProposal(
                             {
                               ...resource,
                               takenPicture: response.result.url,
                             },
                         );
                     this._dataDogService
                         .sendDatadogCustomAction(
                             new DatadogResourceAction(response.result.url));
                   }),
                   catchError((err) => {
                     this._errorHandler(err);
                     throw err;
                   }),
               );

  }

  finishSelfInspection() {

    this._spinnerService.show();

    const proposal = this._proposalService.getProposal();

    const finishInspectionPayload = new FinishInspectionPayloadModel(proposal);

    return this._requestFinishSelfInspection(finishInspectionPayload)
               .pipe(
                   tap((response: FinishInspectionResponseModel) => {
                     this._dataDogService
                         .sendDatadogCustomAction(
                             new DatadogInspectionAction(response.id));
                   }),
                   tap(() => this._spinnerService.hide()),
                   catchError((err) => {
                     this._errorHandler(err);
                     throw err;
                   }),
               );
  }

  private _requestAuth(authToken: string): Observable<ProposalResponseModel> {

    if (environment.useMock) {
      return this.apiService
                 .get('/assets/mock/autenticar.mock.json');
    }

    return this.apiService
               .post({
                 path: '/api/vistoria/autenticar',
                 headers: new HttpHeaders().set(
                     'Authorization',
                     'Bearer ' + authToken,
                 ),
               });
  }

  private _requestUploadFile(fileInformation: UploadFilePayload): Observable<UploadResultResponseModel> {

    if (environment.useMock) {
      return this.apiService
                 .get('/assets/mock/upload.mock.json');
    }

    return this.apiService
               .post({
                 path: '/api/anexo/upload',
                 body: fileInformation,
               });
  }

  private _requestFinishSelfInspection(vistoriaConclusaoData: any) {

    if (environment.useMock) {
      return this.apiService
                 .get('/assets/mock/concluir.mock.json');
    }

    return this.apiService
               .post({
                 path: '/api/vistoria/concluir',
                 body: vistoriaConclusaoData,
               });
  }

  private _errorHandler(err: any) {
    this._spinnerService.hide();
  }
}
