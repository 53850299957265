<div class="self-vistoria-background"
     id="">

  <div class="self-vistoria-header-container">

    <div class="self-vistoria-header">

      <div class="self-vistoria-text-header">
        Instalação do rastreador
      </div>
      <div><img src="{{companyLogo}}"
                class="company-logo vistoria"/></div>
    </div>

  </div>

  <div class="container-self-information">
    <div class="img"><img src="{{successIcon}}"
                          alt=""></div>

    <div class="text">
      <h1>Instalação concluída com sucesso</h1>
      <br>
      <!-- <p>{{successText}}</p> -->
    </div>
  </div>

</div>
