import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CameraService } from '../../shared/services/camera.service';
import { ProposalResource } from '../../shared/models/proposal-resource.model';
import { SelfService, UtilErrors } from '../../shared';
import { AlertService } from '../../shared/services/alert.service';
import { CanvasService } from './canvas.service';
import {
  ProposalErrors,
  ProposalService,
} from '../../shared/services/proposal.service';
import { Router } from '@angular/router';
import { ProposalStatus } from '../../shared/models/proposal-response.model';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss'],
  host: {
    '[class.hidden]': 'true',
  },
})
export class PlayerComponent implements OnInit {

  @ViewChild('player') playerElement: ElementRef<HTMLVideoElement> | undefined;
  @ViewChild('previewImageContainer') previewImageContainerElement: ElementRef<HTMLDivElement> | undefined;
  @ViewChild('previewImage') previewImageElement: ElementRef<HTMLImageElement> | undefined;

  @Output() onCapture = new EventEmitter<string>();

  player: HTMLVideoElement | undefined;
  previewImageContainer: HTMLDivElement | undefined;
  previewImage: HTMLImageElement | undefined;

  capturingPictureResource: ProposalResource | undefined;
  private _capturedImage = '';
  private _isStartingCamera = false;

  constructor(
      private _elementRef: ElementRef,
      private _cameraService: CameraService,
      private _selfService: SelfService,
      private _alertsService: AlertService,
      private _canvasService: CanvasService,
      private _proposalService: ProposalService,
      private _changeDetectorRef: ChangeDetectorRef,
      private _router: Router,
  ) {
  }

  ngOnInit(): void {

    setTimeout(() => {
      if (
        !this.playerElement ||
        !this.previewImageElement ||
        !this.previewImageContainerElement
      ) {
        throw new Error('Necessary elements not found');
      }

      this.player = this.playerElement.nativeElement;
      this.previewImageContainer = this.previewImageContainerElement.nativeElement;
      this.previewImage = this.previewImageElement.nativeElement;
    }, 1);
  }

  async startCamera(pictureResource: ProposalResource) {

    if (!pictureResource) {
      throw new Error('Must provide a picture resource');
    }

    this.capturingPictureResource = pictureResource;
    // trigger on push strategy
    this._changeDetectorRef.markForCheck();

    try {
      // test if it's ok with the proposal
      this._proposalService.getProposal();
    } catch (error) {
      this._alertsService.showAlert(UtilErrors.UNKNOWN);
      return;
    }

    if (this._isStartingCamera) {
      return;
    }

    this._isStartingCamera = true;

    const stream = await this._cameraService.startCamera();
    try {
      await this._attachStreamToPlayer(stream);
      this._lockBodyScroll();
      this._showPlayer();
      this._isStartingCamera = false;
    } catch (e) {
      console.log('Error on startCamera', e);
    }
  }

  async captureImage() {

    if (!this.player) {
      throw new Error('Player not found');
    }

    this._canvasService.drawOnCanvas(this.player);
    await this.stopCamera();
    const imageAsBase64 = this._canvasService.getImageFromCanvas();
    this.showPreviewImage(imageAsBase64);
    this._capturedImage = imageAsBase64;
  }

  async stopCamera() {
    await this._cameraService.stopCamera();
    this._detachStreamFromPlayer();
  }

  showPreviewImage(imageAsBase64: string) {
    if (!this.previewImage) {
      throw new Error('Preview image element not found');
    }

    this.previewImage.src = this._canvasService.getOriginalImage();

    this._toggleImagePreview();
    this._onCapture(imageAsBase64);
  }

  confirmPreviewImage() {
    this.onCapture.emit(this._capturedImage);
    this._toggleImagePreview();
    this._hidePlayer();
    this._releaseBodyScroll();
  }

  cancelPreviewImage() {
    this._capturedImage = '';
    this._toggleImagePreview();

    if (!this.capturingPictureResource) {
      throw new Error(`Capturing picture resource was not found`);
    }
    this.startCamera(this.capturingPictureResource);
  }

  async closePlayer() {
    await this.stopCamera();
    this._releaseBodyScroll();
    this._hidePlayer();
  }

  private _attachStreamToPlayer(stream: MediaStream): Promise<void> {
    if (!this.player) {
      throw new Error('Player not found');
    }
    this.player.srcObject = stream;
    return this.player.play();
  }

  private _detachStreamFromPlayer() {
    if (!this.player) {
      return;
    }
    this.player.pause();
    this.player.srcObject = null;
  }

  private _showPlayer() {
    this._elementRef.nativeElement.classList.remove('hidden');
  }

  private _hidePlayer() {
    this._elementRef.nativeElement.classList.add('hidden');
  }

  private _toggleImagePreview() {
    if (!this.previewImageContainer) {
      throw new Error('Preview image container not found');
    }
    this.previewImageContainer.classList.toggle('hidden');
  }

  private _lockBodyScroll() {
    document.body.classList.add('no-scroll');
  }

  private _releaseBodyScroll() {
    document.body.classList.remove('no-scroll');
  }

  private _onCapture(imageAsString: string) {

    if (!this.capturingPictureResource) {
      throw new Error(`Capturing picture resource was not found`);
    }

    this.capturingPictureResource = {
      ...this.capturingPictureResource,
      takenPicture: imageAsString,
      fileExtension: 'jpeg',
      fileType: 'image',
    };

    this._sendPicture(this.capturingPictureResource);
  }

  private _sendPicture(picture: ProposalResource) {

    this._selfService
        .uploadFile(picture)
        .subscribe({
          error: (err: { msg: string; message: string; status?: ProposalStatus }) => {

            if (err.status === 'Aprovada') {
              this._router.navigate(['/aprovado']);
              return;
            }

            if (err.status === 'Recusada') {
              this._router.navigate(['/erro']);
              return;
            }

            if (err.message === ProposalErrors.PROPOSAL_NOT_FOUND) {
              this._alertsService.showAlert(UtilErrors.UNKNOWN);
              return;
            }

            this._alertsService.showAlert(err.msg);
          },
        });
  }
}
