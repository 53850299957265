import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { ProposalService } from './proposal.service';
import { DatadogCustomAction } from '../models/datadog.model';

export type DatadogCustomPermissionName = 'latitude' | 'longitude' | 'camera_permission' | 'geolocation_permission' | 'orientation_permission';

@Injectable()
export class DatadogService {

  constructor(
      private _proposalService: ProposalService,
  ) {
  }

  setDatadogUser() {
    const proposal = this._proposalService.getProposal();

    if (!proposal) {
      throw new Error('Proposal is required to set datadog user!');
    }

    datadogRum.setUser({
      id: String(proposal.proposal.id),
      name: proposal.proposal.insured.nameOrCompanyName,
      plate: proposal.proposal.vehicle.plate,
      date_limit: proposal.dueDate,
      //  latitude
      //  longitude
      //  camera_permission
      //  geolocation_permission
      //  orientation_permission
    });
  }

  sendDatadogCustomAction(action: DatadogCustomAction) {
    datadogRum.addAction(action.type, action.data);
  }

  updateDatadogUser(property: DatadogCustomPermissionName, value: any) {
    datadogRum.setUserProperty(property, value);
  }
}
