import { Pipe, PipeTransform } from '@angular/core';
import { ProposalQuestionModel } from '../models/proposal-question.model';
import {
  ProposalResponseQuestionStepEnum,
} from '../models/proposal-response.model';

@Pipe({
  name: 'selfQuestionsFilter',
})
export class SelfQuestionsFilterPipe implements PipeTransform {

  transform(
      value: ProposalQuestionModel[],
      ...args: ProposalQuestionModel[]
  ): ProposalQuestionModel[] {

    if (value) {
      return value.filter((question) => question.step
                                        === ProposalResponseQuestionStepEnum.INSPECTION);
    }

    return [];
  }
}
