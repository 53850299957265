import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsService } from '../services/permissions.service';
import { AlertService } from '../services/alert.service';
import { SpinnerService } from '../services/spinner.service';
import { UtilService } from '../services';

@Injectable()
export class GeolocationGuard implements CanLoad {

  private _alertMessage = `Para sua segurança e privacidade, não podemos seguir, com a permissão de Geolocalização negada! Por favor, permita os acessos manualmente e recarregue a página.`;

  constructor(
      private _permissionsService: PermissionsService,
      private _alertsService: AlertService,
      private _router: Router,
      private _spinnerService: SpinnerService,
  ) {
  }

  canLoad(
      route: Route,
      segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    this._spinnerService.show();

    return this._permissionsService
               .getLocationService()
               .then(() => true)
               .catch((error) => {
                 UtilService.log(error);
                 this._alertsService.showAlert(this._alertMessage, true);
                 return this._router.parseUrl('/falha');
               })
               .finally(() => this._spinnerService.hide());
  }
}
