import { Component, OnDestroy } from '@angular/core';
import { LocalStorageService } from 'src/app/shared';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-tracker-success-component',
  templateUrl: './tracker-success.component.html',
  styleUrls: ['./tracker-success.component.css'],
})

export class TrackerSuccessComponent implements OnDestroy {
  companyLogo = environment.company_logo;
  successIcon = environment.success_icon;
  successText = environment.success_text;
  successTitle = environment.success_title;

  constructor(private _localStorage: LocalStorageService) {
  }

  ngOnDestroy(): void {
    this._localStorage.clear();
  }
}
