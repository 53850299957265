export enum ShapeGuideType {
  CHASSI = 'chassi',
  DRIVER_FRONT = 'front-driver-side',
  DRIVER_REAR = 'rear-driver-side',
  PASSENGER_FRONT = 'front-passenger-side',
  PASSENGER_REAR = 'rear-passenger-side',
  PANEL = 'panel',
  VEHICLE_LICENSE = 'vehicle-license',
  DRIVER_LICENSE = 'driver-license',
}

export class ProposalResourceShapeGuide {
  portrait: string;
  landscape: string;
  description = '';

  constructor(type: ShapeGuideType, description?: string) {
    this.portrait = `/assets/svg/${ type }.svg`;
    this.landscape = `/assets/svg/${ type }-landscape.svg`;

    if (description) {
      this.description = description;
    }
  }
}
