import { Injectable } from '@angular/core';
import { ProposalResource } from '../models/proposal-resource.model';
import { environment } from '../../../environments/environment';
import { ProposalQuestionModel } from '../models/proposal-question.model';

export enum UtilErrors {
  UNKNOWN = 'Ocorreu um erro inesperado, por favor, acesse o link e tente novamente',
}

@Injectable()
export class UtilService {

  constructor() {
  }

  getColorStrong(): any {
    return '#7b7b7b';
  }

  resourceTrackBy(index: number, resource: ProposalResource) {
    return resource.id;
  }

  questionTrackBy(index: number, question: ProposalQuestionModel) {
    return question.id;
  }

  static getFileType(fileType: string | null): 'document' | 'image' | '' {

    if (!fileType) {
      return '';
    }

    if (fileType.includes('pdf')) {
      return 'document';
    }

    if (fileType.includes('png')
        || fileType.includes('jpeg')
        || fileType.includes('jpg')) {
      return 'image';
    }

    return '';
  }

  static normalizeString(str: string | number | null): string {

    if (!str) {
      return '';
    }

    return str.toString()
              .normalize('NFD')
        // remove all chars not letters, numbers and spaces (to be replaced)
              .replace(
                  /\p{Diacritic}/gu,
                  '',
              );
  }

  static isQuestionAnswered(question: ProposalQuestionModel): boolean {
    if (!question.required) {
      return true;
    }

    return !!question.answer;
  }

  static log(...args: any[]) {
    if (!environment.production) {
      console.log(...args);
    }
  }

  static isIOS(): boolean {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
  }
}
