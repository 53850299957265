<div class="self-vistoria-background">

  <div class="self-vistoria-header-container">

    <div class="self-vistoria-header">

      <div class="self-vistoria-text-header">
        Vistoria do carro
      </div>
      <div>
        <img src="{{ companyLogo }}"
             alt="Imagem da logo da empresa"
             class="company-logo vistoria"/>
      </div>
    </div>

  </div>

  <div class="container-self-information">
    <div class="img">
      <img src="{{ erroIcon }}"
           alt=""></div>

    <div class="text">
      <h1>{{ erroTitle }}</h1>
      <br>
      <p>{{ erroText }}</p>
    </div>
  </div>

</div>
