<div class="self-vistoria-background">

  <div class="self-vistoria-header-container">

    <div class="self-vistoria-header">

      <div class="self-vistoria-text-header">
        Dispositivo ou navegador não permitido
      </div>

      <div>
        <img src="{{ logo }}"
             alt="Imagem da logo da empresa"
             class="company-logo vistoria"/>
      </div>
    </div>

  </div>

  <div class="disclaimer-container">
    <img class="disclaimer-icon"
         src="{{ icon }}"
         alt="Ícone de vistoria">

    <h1>{{ title }}</h1>
    <br>
    <p>
      Infelizmente seu dispositivo ou navegador não permite que façamos a
      vistoria :(
      <br>
      <br>
      Por favor, acesse de um dispositivo móvel, Android ou iPhone, utilizando
      os navegadores Google Chrome, Firefox ou Safari.
    </p>
  </div>

</div>
