import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {

  private _storageKey = 'authToken';

  constructor(private _localStorage: LocalStorageService) {
  }

  set(token: string) {
    this._localStorage.set(this._storageKey, token);
  }

  get() {
    return this._localStorage.get(this._storageKey);
  }

  clearToken() {
    this._localStorage.remove(this._storageKey);
  }
}
