import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-erro-component',
  templateUrl: './erro.component.html',
  styleUrls: ['./erro.component.css'],
})

export class ErroComponent {
  companyLogo = environment.company_logo;
  erroIcon = environment.erro_icon;
  erroText = environment.erro_text;
  erroTitle = environment.erro_title;
}
