<div class="self-vistoria-background"
     id="">
  <div class="self-vistoria-header-container">
    <div class="self-vistoria-header">
      <div class="self-vistoria-text-header">Instalação do rastreador</div>
      <div><img src="{{ companyLogo }}"
                class="company-logo vistoria"/></div>
    </div>

    <div class="container-stepper">
      <label class="label-stepper-separator"> Dados </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Pré instalação </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator active"> Fotos </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Pós instalação </label>
    </div>
  </div>

  <div class="container-self-information">
    <div class="container-text-seft-photo">
      <p class="text-form">
        Para enviar as fotos da instalação, toque em cada uma das imagens
        abaixo.
      </p>
    </div>

    <div class="container-stepper alert">
      <p>
        Dica importante: tire as fotos durante o dia, ou num local com boa
        iluminação.
      </p>
    </div>

    <ng-container *ngIf="proposal">
      <div class="container-btns">
        <div *ngFor="let picture of proposal.pictures; trackBy: utilService.resourceTrackBy"
             class="container-btn-photo position-relative">

          <div class="file-upload form-group col-xs-2 col-md-3 button-fechamento-adicionar-container non-padding">
            <ng-container *ngIf="!proposal.proposal.isPending; else isProposalPendingBlock">
              <div (click)="getOrientationPermission(picture)"
                   *ngIf="!picture.takenPicture; else hasTakenPictureBlock"
                   class="button-fechamento-adicionar icon">
                <img src="{{ picture.icon }}"
                     alt="Imagem explicando como a foto deve ser tirada nesse espaço"
                     class="image-self-photo"/>
              </div>

              <ng-template #hasTakenPictureBlock>
                <div (click)="getOrientationPermission(picture)"
                     class="button-fechamento-adicionar">
                  <img alt="Ícone de edição"
                       class="edit-icon"
                       src="/assets/img/edit-icon.svg"/>
                  <img src="{{ picture.takenPicture }}"
                       alt="Foto tirada pelo usuário"
                       class="image-self-photo"/>
                </div>
              </ng-template>
            </ng-container>

            <ng-template #isProposalPendingBlock>

              <ng-container *ngIf="picture.isPending; else pictureNotPendingBlock">
                <div (click)="getOrientationPermission(picture)">
                  <ng-container *ngIf="!picture.takenPicture; else hasTakenPendingPictureBlock">
                    <div class="button-fechamento-adicionar icon">
                      <img src="{{ picture.icon }}"
                           alt="Imagem explicando como a foto deve ser tirada nesse espaço"
                           class="image-self-photo"/>
                    </div>
                  </ng-container>

                  <ng-template #hasTakenPendingPictureBlock>
                    <div class="button-fechamento-adicionar">
                      <img alt="Ícone de edição"
                           class="edit-icon"
                           src="/assets/img/edit-icon.svg"/>
                      <img src="{{ picture.takenPicture }}"
                           alt="Foto tirada pelo usuário"
                           class="image-self-photo"/>
                    </div>
                  </ng-template>
                </div>
              </ng-container>

              <ng-template #pictureNotPendingBlock>
                <div *ngIf="!!picture.takenPicture"
                     class="button-fechamento-adicionar">
                  <img alt="Ícone de preto e branco de imagem enviada"
                       class="image-self-photo"
                       src="{{ picture.sentIcon }}"/>
                </div>
              </ng-template>

            </ng-template>

          </div>

          <label class="text-btn-photo">{{ picture.description }}</label>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!!errorMessage"
         class="col-12 container-erro-text-message">
      <label class="erro-text-message">{{ errorMessage }}</label>
    </div>

    <div class="col-12 container-erro-text-message">
      <label class="alert-text-message">
        Antes de continuar, confira se as fotos estão nítidas e mostrando o
        ângulo correto.
      </label>
    </div>

    <button class="layout-btn-overall layout-btn-label-overall"
            [ngClass]="{
            success: canSubmitPictures(),
            fail: !canSubmitPictures()
            }"
            [disabled]="!canSubmitPictures()"
            (click)="submit()">
      Continuar
    </button>
  </div>
</div>

<div class="footer">
  <mat-icon class="material-symbols-outlined"> lock</mat-icon>
  <span>usebens.com.br</span>
</div>

<app-player (onCapture)="onCapture($event)"></app-player>
