import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertComponent } from '../../components/self/alert/alert.component';
import { DatadogService } from './datadog.service';
import { DatadogAlertAction } from '../models/datadog.model';

@Injectable({
  providedIn: 'root',
})
export class AlertService {

  constructor(
      public dialog: MatDialog,
      private _dataDogService: DatadogService,
  ) {
  }

  showAlert(
      text: string | null,
      disableClose?: boolean,
  ): MatDialogRef<AlertComponent, any> {

    this._dataDogService
        .sendDatadogCustomAction(new DatadogAlertAction(text));

    return this.dialog
               .open(AlertComponent, {
                 width: '100%',
                 data: { text, disableClose },
                 // role: 'alertdialog',
                 disableClose,
               });
  }
}
