import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelfService, UtilErrors, UtilService } from 'src/app/shared';
import { environment } from '../../../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { ProposalModel } from '../../../shared/models/proposal.model';
import { ProposalService } from '../../../shared/services/proposal.service';
import { MatRadioChange } from '@angular/material/radio';
import {
  ProposalQuestionModel,
} from '../../../shared/models/proposal-question.model';
import { AlertService } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-tracker-checklist-pos',
  templateUrl: './tracker-checklist-pos.component.html',
  styleUrls: ['./tracker-checklist-pro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrackerChecklistPosComponent implements OnInit {

  companyLogo = environment.company_logo;

  proposal: ProposalModel | undefined;
  questions: ProposalQuestionModel[] = [];

  constructor(
      private _router: Router,
      private _selfService: SelfService,
      private _proposalService: ProposalService,
      private _alertsService: AlertService,
      public utilService: UtilService,
  ) {

  }

  ngOnInit() {

    try {
      this.proposal = this._proposalService.getProposal();
      this.questions = this._proposalService.getTrackerInstallationPostQuestions();
      this.canSubmitChecklist();
    } catch (e) {
      this._alertsService.showAlert(UtilErrors.UNKNOWN);
    }
  }

  canSubmitChecklist(): boolean {

    if (!this.proposal) {
      datadogRum.addError(`Proposal not found in ${ TrackerChecklistPosComponent.name } ${ this.canSubmitChecklist.name } method`);
      return false;
    }

    return this._proposalService
               .getTrackerInstallationPostQuestions()
               .every(UtilService.isQuestionAnswered);
  }

  onQuestionChange(event: MatRadioChange, question: ProposalQuestionModel) {
    this._proposalService
        .updateQuestionInProposal({
          ...question,
          answer: event.value ? event.value : '',
        } as ProposalQuestionModel);

    this._updateProposalFromStorage();
    this.canSubmitChecklist();
  }

  onInputChange(event: Event, question: ProposalQuestionModel) {

    if (!event.target) {
      throw new Error('Event target not found');
    }

    const element = event.target as HTMLInputElement;

    this._proposalService
        .updateQuestionInProposal({
          ...question,
          answer: element.value ? element.value : '',
        } as ProposalQuestionModel);

    this._updateProposalFromStorage();
    this.canSubmitChecklist();
  }

  submit() {

    if (!this.proposal) {
      datadogRum.addError(`Proposal not found in ${ TrackerChecklistPosComponent.name } ${ this.submit.name } method`);
      return;
    }

    this._selfService
        .finishSelfInspection()
        .subscribe({
          next: () => {
            this._router.navigateByUrl('/self/sucesso-tracker');
          },
        });
  }

  private _updateProposalFromStorage() {
    this.proposal = this._proposalService.getProposal();
  }
}
