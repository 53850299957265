import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class SpinnerService {

  private _rootSpinnerName = 'root-spinner';

  constructor(private _ngxSpinnerService: NgxSpinnerService) {
  }

  async show(): Promise<unknown> {
    return this._ngxSpinnerService
               .show(this._rootSpinnerName);
  }

  async hide(): Promise<unknown> {
    return this._ngxSpinnerService
               .hide(this._rootSpinnerName);
  }
}
