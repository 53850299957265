import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AllowedBrowsersGuard implements CanLoad {

  constructor(private _router: Router) {
  }

  canLoad(
      route: Route,
      segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const userAgent = navigator.userAgent;

    const miUiBrowserRegExp = /MiuiBrowser/i;

    const desktopRegExp = /Linux(?!.*Android)|Windows NT|Macintosh/i;

    const deniedBrowsers = [
      miUiBrowserRegExp,
    ];

    const deniedDevices = [
      desktopRegExp
    ];

    const isDenied = [
      ...deniedBrowsers,
      ...deniedDevices,
    ]
        .some((regExp) => userAgent.match(regExp));

    if (isDenied) {
      return this._router.parseUrl('/navegador-incompativel');
    }

    return true;
  }
}
