export type DatadogActionsType =
    'alert'
    | 'resource'
    | 'question'
    | 'proposal'
    | 'inspection';

export interface DatadogCustomAction {
  type: DatadogActionsType,
  data: any
}

export class DatadogAlertAction implements DatadogCustomAction {
  type: DatadogActionsType = 'alert';
  data: {
    message: string,
  };

  constructor(message: string | null) {
    if (!message) {
      message = `Disparou o alerta sem texto!`;
    }
    message = `Disparou o alerta com o texto: ${ message }`;
    this.data = { message };
  }
}

export class DatadogResourceAction implements DatadogCustomAction {
  type: DatadogActionsType = 'resource';
  data: {
    resource: string,
  };

  constructor(resource: string) {
    this.data = { resource };
  }
}

export class DatadogInspectionAction implements DatadogCustomAction {
  type: DatadogActionsType = 'inspection';
  data: {
    inspection: string,
  };

  constructor(inspectionId: number) {
    this.data = { inspection: `Proposta ${ inspectionId } finalizada` };
  }
}
