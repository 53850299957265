import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import {
  UnauthorizedComponent,
} from '../components/self-vistoria-falha/unauthorized.component';
import {
  SelfVistoriaSucessoComponent,
} from '../components/self-vistoria-sucesso/self-vistoria-sucesso.component';
import { ErroComponent } from '../components/self-vistoria-erro/erro.component';
import { SelfVistoriaAprovadoComponent } from '../components/self-vistoria-aprovado/self-vistoria-aprovado.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxSpinnerModule,
  ],
  declarations: [
    UnauthorizedComponent,
    SelfVistoriaSucessoComponent,
    SelfVistoriaAprovadoComponent,
    ErroComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgxSpinnerModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
