import {
  ProposalResponseModel,
  ProposalResponseQuestion,
  ProposalResponseResource, ProposalStatus,
} from './proposal-response.model';
import { ProposalResource } from './proposal-resource.model';
import { ProposalQuestionModel } from './proposal-question.model';

export enum ProposalProductEnum {
  TRACKER = 2,
}

export interface ProposalVehicle {
  brand: string | undefined;
  model: string | undefined;
  year: number | undefined;
  isZeroKm: false | undefined;
  version: string | undefined;
  plate: string | undefined;
  chassi: string | undefined;
  renavam: string | undefined;
  fipe: string | null | undefined;
}

export interface ProposalDriver {
  cpf: string | undefined;
  name: string | undefined;
  birthday: string | undefined; // date string
}

export interface ProposalProduct {
  id: number | undefined;
  description: string | undefined;
  coverage: string | null | undefined; // ???
}

export interface ProposalAddress {
  cep: string | undefined;
  street: string | undefined;
  neighborhood: string | undefined;
  addressNumber: string | undefined;
  complement: string | null | undefined;
  province: string | undefined;
  city: string | undefined;
}

export interface ProposalInsured {
  cpfCnpj: string | undefined;
  nameOrCompanyName: string | undefined;
  socialName: string | undefined;
  birthdayOrFoundation: string | undefined; // date string
  ddd: string | undefined;
  phone: string | undefined;
  email: string | undefined;
  mainDriver: boolean | undefined;
  address: ProposalAddress | undefined;
}

export class ProposalModel {
  id: string;
  dueDate: string; // date string
  proposal: {
    id: number | undefined;
    beginningOfTerm: string | null | undefined; // date string
    analysisType: number | undefined;
    isPending: boolean | undefined;
    insured: ProposalInsured;
    vehicle: ProposalVehicle;
    driver: ProposalDriver;
    product: ProposalProduct;
  };
  pictures: ProposalResource[];
  documents: ProposalResource[];
  selfie: string | null;
  questions: ProposalQuestionModel[];
  message: string | null;
  isSent: boolean;
  isReSent: boolean;
  status: ProposalStatus | undefined;

  constructor(proposalResponse: ProposalResponseModel) {
    this.id = proposalResponse.id;
    this.dueDate = proposalResponse.prazo_conclusao;
    this.proposal = {
      id: proposalResponse.proposta?.numero,
      beginningOfTerm: proposalResponse.proposta?.inicio_vigencia,
      analysisType: proposalResponse.proposta?.tipo_analise,
      isPending: proposalResponse.proposta?.pendente,
      insured: {
        cpfCnpj: proposalResponse.proposta?.segurado.cpf_cnpj,
        nameOrCompanyName:
          proposalResponse.proposta?.segurado.nome_razao_social,
        socialName:
          proposalResponse.proposta?.segurado.nome_social,
        birthdayOrFoundation:
          proposalResponse.proposta?.segurado.data_nascimento_fundacao,
        ddd: proposalResponse.proposta?.segurado.fone_principal_ddd,
        phone: proposalResponse.proposta?.segurado.fone_principal,
        email: proposalResponse.proposta?.segurado.email,
        mainDriver: proposalResponse.proposta?.segurado.principal_condutor,
        address: {
          cep: proposalResponse.proposta?.segurado.endereco.cep,
          street: proposalResponse.proposta?.segurado.endereco.logradouro,
          neighborhood: proposalResponse.proposta?.segurado.endereco.bairro,
          addressNumber: proposalResponse.proposta?.segurado.endereco.numero,
          complement: proposalResponse.proposta?.segurado.endereco.complemento,
          province: proposalResponse.proposta?.segurado.endereco.uf,
          city: proposalResponse.proposta?.segurado.endereco.cidade,
        },
      },
      vehicle: {
        brand: proposalResponse.proposta?.veiculo.marca,
        model: proposalResponse.proposta?.veiculo.modelo,
        year: proposalResponse.proposta?.veiculo.ano,
        isZeroKm: proposalResponse.proposta?.veiculo.zero_km,
        version: proposalResponse.proposta?.veiculo.versao,
        plate: proposalResponse.proposta?.veiculo.placa,
        chassi: proposalResponse.proposta?.veiculo.chassi,
        renavam: proposalResponse.proposta?.veiculo.renavam,
        fipe: proposalResponse.proposta?.veiculo.fipe,
      },
      driver: {
        cpf: proposalResponse.proposta?.condutor.cpf,
        name: proposalResponse.proposta?.condutor.nome,
        birthday: proposalResponse.proposta?.condutor.data_nascimento,
      },
      product: {
        id: proposalResponse.proposta?.produto.id,
        description: proposalResponse.proposta?.produto.descricao,
        coverage: proposalResponse.proposta?.produto.coberturas,
      },
    };
    this.pictures = this._sanitizeResources(proposalResponse.fotos);
    this.documents = this._sanitizeResources(proposalResponse.documentos);
    this.selfie = proposalResponse.selfie;
    this.questions = this._sanitizeQuestions(proposalResponse.questionarios);
    this.message = proposalResponse.msg;
    this.isSent = proposalResponse.enviada;
    this.isReSent = proposalResponse.reenviada;
    this.status = proposalResponse.status;
  }

  private _sanitizeResources(
    pictures: ProposalResponseResource[]
  ): any{
    if (pictures) {
      return pictures.map(
        (picture) => new ProposalResource(picture, this.proposal.isPending)
      );
    }
    return undefined;
  }

  private _sanitizeQuestions(
    questions: ProposalResponseQuestion[]
  ): ProposalQuestionModel[] {
    if (!questions || !questions.length) {
      return [];
    }

    return questions.map((question) => new ProposalQuestionModel(question));
  }
}
