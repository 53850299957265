<div class="self-vistoria-background">

  <div class="self-vistoria-header-container">

    <div class="self-vistoria-header">

      <div class="self-vistoria-text-header">
        Vistoria do carro
      </div>
      <div>
        <img src="{{ companyLogo }}"
             alt="Logo do parceiro"
             class="company-logo vistoria"/>
      </div>
    </div>

  </div>

  <div class="container-self-information">
    <div class="img">
      <img src="{{ aprovadoIcon }}"
           alt="Vistoria enviada com sucesso!">
    </div>

    <div class="text">
      <h1>{{ aprovadoTitle }}</h1>
      <br>
      <p>{{ aprovadoText }}</p>
    </div>
  </div>

</div>
