import { FormBuilder, FormGroup } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, ReplaySubject } from 'rxjs';
import { SelfService, UtilErrors, UtilService } from 'src/app/shared';
import { environment } from '../../../../environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { ProposalModel } from '../../../shared/models/proposal.model';
import {
  ProposalResource,
} from '../../../shared/models/proposal-resource.model';
import {
  PermissionsService,
} from '../../../shared/services/permissions.service';
import { PlayerComponent } from '../../player/player.component';
import { ProposalService } from '../../../shared/services/proposal.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import {
  BottomSheetComponent,
} from '../../bottom-sheet/bottom-sheet.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AlertService } from '../../../shared/services/alert.service';
import {
  ProposalQuestionModel,
} from '../../../shared/models/proposal-question.model';
import { ProposalStatus } from '../../../shared/models/proposal-response.model';

@Component({
  selector: 'app-self-documentos-component',
  templateUrl: './self-documentos.component.html',
  styleUrls: ['./self-documentos.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class SelfDocumentosComponent implements OnInit {

  @ViewChild(PlayerComponent)
  playerComponent: PlayerComponent | undefined;

  @ViewChild('fileInput')
  fileInputElement: ElementRef<HTMLInputElement> | undefined;

  @Input()
  requiredFileType: string = 'pdf,jpeg,png';

  companyLogo = environment.company_logo;
  pdfIcon = environment.pdf_icon;
  doc = [environment.client_doc, environment.car_doc];
  docD = [environment.client_doc_d, environment.car_doc_d];

  proposal: ProposalModel | undefined;
  questions: ProposalQuestionModel[] = [];
  selfieForm: FormGroup;

  capturingResource: ProposalResource | undefined;

  constructor(
      private _formBuilder: FormBuilder,
      private _router: Router,
      private _selfService: SelfService,
      private _alertsService: AlertService,
      private _permissionsService: PermissionsService,
      private _bottomSheet: MatBottomSheet,
      private _proposalService: ProposalService,
      public utilService: UtilService,
  ) {

    this.selfieForm = this._formBuilder.group({
      observacao: [''],
    });
  }

  async ngOnInit() {
    try {
      this.proposal = this._proposalService.getProposal();
      this.questions = this._proposalService.getSelfInspectionQuestions();
    this.questions = this._proposalService.getSelfInspectionQuestions();
      this.canSubmitDocuments();
    } catch (e) {
      this._alertsService.showAlert(UtilErrors.UNKNOWN);
    }
  }

  canSubmitDocuments(): boolean {
    try {

      const proposal = this._proposalService.getProposal();

      const hasAllDocumentsResources = proposal.documents
                                               .every((document: ProposalResource) =>
                                                   !!document.takenPicture);

      const isQuestionsAnswered = this._proposalService
                                      .getSelfInspectionQuestions()
                                      .every(UtilService.isQuestionAnswered);

      return hasAllDocumentsResources && isQuestionsAnswered;

    } catch (erro) {
      UtilService.log(erro);
      return false;
    }
  }

  // needs to call this method before takePicture to ensure user gesture.
  async getOrientationPermission(resource: ProposalResource) {
    await this._permissionsService.getOrientationPermission();
    this.openBottomSheet(resource);
  }

  async openBottomSheet(resource: ProposalResource) {
    this._checkDocumentPendency(resource)
        .subscribe({
          next: () => {
            this.capturingResource = resource;
            this._bottomSheet
                .open(BottomSheetComponent, {
                  data: {
                    takePicture: () => this.takePicture(resource),
                    selectFile: () => this.selectFile(),
                  },
                });
          },
        });
  }

  async selectFile() {

    if (!this.fileInputElement) {
      throw new Error('File input was not found');
    }

    this.fileInputElement.nativeElement.click();
  }

  async takePicture(resource: ProposalResource) {

    if (!this._permissionsService.getProjectPermissions().camera) {
      try {
        await this._permissionsService.getCameraPermission();
        await this._startCamera(resource);
      } catch (e) {
        this._alertsService.showAlert(
            `Para sua segurança e privacidade, não podemos seguir, com a
            permissão de camera negada! Por favor, permita os acessos
            manualmente e recarregue a página.`,
            true,
        );
      }
      return;
    }

    try {
      await this._startCamera(resource);
    } catch (erro) {
      datadogRum.addError(erro);
    }
  }

  onCapture($event: string) {
    this.canSubmitDocuments();
    this._updateProposalFromStorage();
  }

  onQuestionChange(event: MatCheckboxChange, question: ProposalQuestionModel) {
    this._proposalService
        .updateQuestionInProposal({
          ...question,
          answer: event.checked ? question.description : '',
        } as ProposalQuestionModel);

    this._updateProposalFromStorage();
    this.canSubmitDocuments();
  }

  async onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (!file) {
      console.warn('File was not selected by user');
      return;
    }

    if (UtilService.getFileType(file.type)
        !== 'document'
        && UtilService.getFileType(file.type)
        !== 'image') {
      this._alertsService.showAlert('Extensão do arquivo não aceito para este anexo. Por favor, selecione um arquivo PDF ou imagem.');
      this._clearFileInputSelection();
      return;
    }

    this.convertFile(file)
        .subscribe((base64) => {

          if (!this.capturingResource) {
            throw new Error(`Capturing picture was not set in ${ this._checkDocumentPendency.name } subscription`);
          }

          this.capturingResource = {
            ...this.capturingResource,
            takenPicture: base64,
            fileExtension: file.type,
            fileType: UtilService.getFileType(file.type),
          };

          this._sendDocument(this.capturingResource);
          this._clearFileInputSelection();
        });
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event: any) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  submit() {
    try {

      const proposal = this._proposalService.getProposal();

      if (!this.canSubmitDocuments()) {
        this._alertsService.showAlert(`Por favor, envie todos os documentos pendentes para prosseguir.`);
        return;
      }

      this._selfService
          .finishSelfInspection()
          .subscribe({
            next: () => {
              if (proposal.proposal.isPending) {
                this._router.navigateByUrl('/reenvio');
                return;
              }

              this._router.navigateByUrl('/sucesso');
            },
            error: (err: { msg: string; statusAtual: ProposalStatus }) => {
              if (err.statusAtual === 'Aprovada') {
                this._router.navigate(['/aprovado']);
                return;
              }

              if (err.statusAtual === 'Recusada') {
                this._router.navigate(['/erro']);
              }
            },
          });
    } catch (erro) {
      datadogRum.addError(erro);
    }
  }

  private async _startCamera(resource: ProposalResource): Promise<void> {

    if (!this.playerComponent) {
      return;
    }

    await this.playerComponent.startCamera(resource);
  }

  private _checkDocumentPendency(document: ProposalResource) {
    const hasPicturePendency = document.isPending && !!document.pendencyReason;

    if (!hasPicturePendency) {
      return of(false);
    }

    return this._alertsService
               .showAlert(document.pendencyReason)
               .afterClosed();
  }

  private _sendDocument(document: ProposalResource) {

    this._selfService
        .uploadFile(document)
        .subscribe({
          next: () => {
            this.canSubmitDocuments();
            this._updateProposalFromStorage();
          },
          error: (err: { msg: string, status: ProposalStatus }) => {

            if (err.status === 'Aprovada') {
              this._router.navigate(['/aprovado']);
              return;
            }

            if (err.status === 'Recusada') {
              this._router.navigate(['/erro']);
              return;
            }

            this._alertsService.showAlert(err.msg);
          },
        });
  }

  private _updateProposalFromStorage() {
    this.proposal = this._proposalService.getProposal();
  }

  private _clearFileInputSelection() {

    if (!this.fileInputElement) {
      throw new Error('File input was not found');
    }

    // clean input selected file to fire change event when select the
    // same file
    this.fileInputElement.nativeElement.value = '';
  }
}
