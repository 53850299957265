<router-outlet></router-outlet>

<ngx-spinner name="root-spinner"
             bdColor="rgba(255, 255, 255, 0.8)"
             size="default"
             color="#7b7b7b"
             type="ball-beat"
             [fullScreen]="true">
  <p class="loading-text">Carregando...</p>
</ngx-spinner>
