<div class="self-vistoria-background">

  <div class="self-vistoria-header-container">

    <div class="self-vistoria-header">

      <div class="self-vistoria-text-header">
        Vistoria do carro
      </div>
      <div>
        <img src="{{ companyLogo }}"
             class="company-logo vistoria"/>
      </div>
    </div>

    <div class="container-stepper">

      <label class="carro-step"
             matBadge="done"
             matBadgeSize="medium">
        Carro
      </label>

      <label class="documentos-step"
             matBadge="2"
             matBadgeSize="medium">
        Documentos
      </label>
    </div>

  </div>

  <div *ngIf="proposal"
       class="container-self-information">

    <div class="container-text-seft-photo">
      <p class="text-form">
        Agora, envie a foto da CNH do motorista principal e a foto do
        licenciamento mais atual do carro vigente.
      </p>

      <!-- <p class="text-form" *ngIf="this.dataProposta.proposta.pendente === false">
              Último passo! Agora, envie sua CNH e o documento do carro.
          </p>

          <p class="text-form" *ngIf="this.dataProposta.proposta.pendente == true && this.listaDocumentos.length > 0">
              Seus documentos não puderam ser aceitos, tire a foto novamente ou escolha da galeria.
          </p> -->
    </div>

    <div class="container-btns">

      <input #fileInput
             type="file"
             class="file-input"
             [accept]="requiredFileType"
             (input)="onFileSelected($event)"/>

      <div *ngFor="let resource of proposal.documents; index as forIndex; trackBy: utilService.resourceTrackBy"
           class="container-btn-photo position-relative">

        <!---------------------------------------------------------------------------------------------------------------------------------------------->

        <div class="file-upload form-group col-xs-2 col-md-3 button-fechamento-adicionar-container non-padding">

          <ng-container *ngIf="!proposal.proposal.isPending; else isProposalPendingBlock">
            <div (click)="getOrientationPermission(resource)"
                 *ngIf="!resource.takenPicture; else hasTakenPictureBlock"
                 class="button-fechamento-adicionar icon">
              <img src="{{ doc[forIndex] ? doc[forIndex] : resource.icon }}"
                   alt="Por favor envie a foto do documento"
                   class="image-self-photo"/>
            </div>
            <ng-template #hasTakenPictureBlock>
              <div (click)="getOrientationPermission(resource)"
                   class="button-fechamento-adicionar">
                <img alt="Ícone de edição"
                     class="edit-icon"
                     src="/assets/img/edit-icon.svg"/>
                <img *ngIf="resource.fileType === 'image'"
                     alt="Foto tirada pelo usuário"
                     src="{{ resource.takenPicture }}"
                     class="image-self-photo"/>
                <img *ngIf="resource.fileType === 'document'"
                     alt="Ícone de documento selecionado"
                     src="{{ pdfIcon }}"
                     class="image-self-photo"/>
              </div>
            </ng-template>
          </ng-container>

          <ng-template #isProposalPendingBlock>

            <ng-container *ngIf="resource.isPending; else resourceNotPendingBlock">
              <div (click)="getOrientationPermission(resource)">
                <ng-container *ngIf="!resource.takenPicture; else hasTakenPendingPictureBlock">
                  <div class="button-fechamento-adicionar icon">
                    <img src="{{ doc[forIndex] ? doc[forIndex] : resource.icon }}"
                         alt="Precisamos da foto do documento"
                         class="image-self-photo"/>
                  </div>
                </ng-container>

                <ng-template #hasTakenPendingPictureBlock>
                  <div class="button-fechamento-adicionar">
                    <img alt="Ícone de edição"
                         class="edit-icon"
                         src="/assets/img/edit-icon.svg"/>
                    <img *ngIf="resource.fileType === 'image'"
                         alt="Foto tirada pelo usuário"
                         src="{{ resource.takenPicture }}"
                         class="image-self-photo"/>
                    <img *ngIf="resource.fileType === 'document'"
                         alt="Ícone de documento selecionado"
                         src="{{ pdfIcon }}"
                         class="image-self-photo"/>
                  </div>
                </ng-template>
              </div>
            </ng-container>

            <ng-template #resourceNotPendingBlock>
              <div *ngIf="!!resource.takenPicture"
                   class="button-fechamento-adicionar done">
                <img alt="Ícone de finalizado"
                     class="edit-icon"
                     src="/assets/img/done-icon.png"/>
                <img alt="Ícone de preto e branco de imagem enviada"
                     class="image-self-photo"
                     src="{{ docD[forIndex] ? docD[forIndex] : resource.icon }}"/>
              </div>
            </ng-template>

          </ng-template>
        </div>

        <!---------------------------------------------------------------------------------------------------------------------------------------------->

        <label class="text-btn-photo">{{ resource.description }}</label>
      </div>

    </div>

    <form [formGroup]="selfieForm">

      <div class="container-selfie-questionario inline-flex-items-center">

        <div *ngFor="let question of questions | selfQuestionsFilter"
             class="confirmacao">
          <mat-checkbox #matCheckbox
                        type="checkbox"
                        id="scales"
                        data-dd-action-name="Pergunta de confirmação: {{ question.description }}"
                        name="scales"
                        [(ngModel)]="question.answer"
                        (change)="onQuestionChange($event, question)"
                        [ngModelOptions]="{standalone: true}"
                        class="input-self-selfie-checkbox"></mat-checkbox>
          <label [for]="matCheckbox.inputId">
            {{ question.description }}
          </label>
        </div>

      </div>

      <button class="layout-btn-overall layout-btn-label-overall"
              [class.success]="canSubmitDocuments()"
              (click)="submit()"
              [disabled]="!canSubmitDocuments()">
        Finalizar vistoria
      </button>

    </form>

  </div>

</div>

<div class="footer">
  <mat-icon class="material-symbols-outlined">
    lock
  </mat-icon>
  <span>usebens.com.br</span>
</div>

<app-player (onCapture)="onCapture($event)"></app-player>
