import { CommonModule, registerLocaleData } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ApiService, UtilService } from 'src/app/shared';
import { SelfService } from 'src/app/shared/services/self.service';
import { SharedModule } from 'src/app/shared/shared.modules';
import {
  SelfDocumentosComponent,
} from './self-documentos/self-documentos.component';
import { SelfFotoComponent } from './self-fotos/self-foto.component';
import { SelfComponent } from './self.component';
import { SelfRoutingModule } from './self.routing.module';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { AlertComponent } from './alert/alert.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import ptBr from '@angular/common/locales/pt';
import { PlayerComponent } from '../player/player.component';
import { MatListModule } from '@angular/material/list';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';
import {
  TrackerQuestionarioComponent,
} from './tracker-questionario/tracker-questionario.component';
import {
  TrackerChecklistPreComponent,
} from './tracker-checklist-pre/tracker-checklist-pre.component';
import { TrackerFotoComponent } from './tracker-fotos/tracker-foto.component';
import {
  TrackerChecklistPosComponent,
} from './tracker-checklist-pos/tracker-checklist-pos.component';
import {
  TrackerSuccessComponent,
} from './self-rastreador-sucesso/tracker-success.component';
import { MatInputModule } from '@angular/material/input';
import { SelfQuestionsFilterPipe } from '../../shared/pipes/self-questions-filter.pipe';

registerLocaleData(ptBr);

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    SelfRoutingModule,
    MatBadgeModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDividerModule,
    MatListModule,
    MatBottomSheetModule,
    MatRadioModule,
    MatInputModule,
  ],
  declarations: [
    TrackerFotoComponent,
    TrackerChecklistPreComponent,
    TrackerChecklistPosComponent,
    TrackerQuestionarioComponent,
    TrackerSuccessComponent,
    SelfComponent,
    SelfFotoComponent,
    SelfDocumentosComponent,
    AlertComponent,
    PlayerComponent,
    BottomSheetComponent,
    // pipes
    SelfQuestionsFilterPipe,
  ],
  providers: [
    SelfService,
    ApiService,
    UtilService,
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR',
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SelfModule {}
