import { Injectable } from '@angular/core';

@Injectable()
export class CameraService {

  private _stream: MediaStream | undefined;

  constructor() {
  }

  async getCameraStream(): Promise<MediaStream> {
    this._stream = await navigator.mediaDevices
                                  .getUserMedia({
                                    video: {
                                      width: {
                                        ideal: 4096,
                                      },
                                      height: {
                                        ideal: 2160,
                                      },
                                      aspectRatio: {
                                        ideal: 1.7777777778,
                                      },
                                      facingMode: 'environment',
                                    },
                                    audio: false,
                                  });
    return this._stream;
  }

  async startCamera(): Promise<MediaStream> {
    return await this.getCameraStream();
  }

  async stopCamera() {
    if (!this._stream) {
      throw new Error('Stream not found');
    }

    this._stream
        .getTracks()
        .forEach((track) => track.stop());
  }
}
