<video #player
       muted
       playsinline
       class="video"></video>

<div class="frame">
  <div class="frame-guide frame-guide-top"></div>
  <div class="frame-guide frame-guide-right"></div>
  <div class="frame-guide frame-guide-bottom"></div>
  <div class="frame-guide frame-guide-left"></div>

  <ng-container *ngIf="capturingPictureResource">
    <div *ngIf="capturingPictureResource.shapeGuide.description"
         class="frame-description">
      {{ capturingPictureResource.shapeGuide.description }}
    </div>

    <ion-icon *ngIf="capturingPictureResource.shapeGuide.portrait"
              class="shape-guide orientation-portrait"
              src="{{ capturingPictureResource.shapeGuide.portrait }}"></ion-icon>

    <ion-icon *ngIf="capturingPictureResource.shapeGuide.landscape"
              class="shape-guide orientation-landscape"
              src="{{ capturingPictureResource.shapeGuide.landscape }}"></ion-icon>
  </ng-container>
</div>

<div (click)="captureImage()"
     data-dd-action-name="Capturar foto"
     class="capture-image-button"></div>

<div (click)="closePlayer()"
     data-dd-action-name="Fechar player de captura de foto"
     class="close-button">
  <svg width="24"
       height="25"
       viewBox="0 0 24 25"
       fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.77845 12.5L0.339111 3.06065L2.46043 0.939331L11.8998 10.3787L21.3391 0.939331L23.4604 3.06065L14.0211 12.5L23.4604 21.9393L21.3391 24.0607L11.8998 14.6213L2.46043 24.0607L0.339111 21.9393L9.77845 12.5Z"
          fill="currentColor"/>
  </svg>
</div>

<div #previewImageContainer
     class="preview-image-container hidden">
  <!--empty src just to hide WebStorm warning-->
  <img #previewImage
       alt="Imagem de pré-visualização do carro"
       class="preview-image"
       src="">
  <div class="preview-image-buttons">
    <div (click)="cancelPreviewImage()"
         class="preview-image-button layout-btn-overall">
      Tirar outra foto
    </div>
    <div (click)="confirmPreviewImage()"
         class="preview-image-button layout-btn-overall">
      Confirmar foto
    </div>
  </div>
</div>
