export enum ProposalResponseQuestionStepEnum {
  INSPECTION = 'VISTORIA',
  PRE_INSTALLATION = 'PRE_INSTALACAO',
  POST_INSTALLATION = 'POS_INSTALACAO',
}

export enum ProposalResponseResourceTypeEnum {
  pictures = 'FOTO',
  documents = 'DOCUMENTO',
}

export type ProposalStatus =
    'Aguardando Vistoria'
    | 'Enviada'
    | 'Análise'
    | 'Aprovada'
    | 'Pendente'
    | 'Recusada'
    | 'Expirada';

export interface ProposalResponseResource {
  id: string;
  tipo: string;
  descricao: string | null;
  tipo_arquivo_aceitos: string[];
  icone: string | null;
  layout: string | null;
  fotoTirada: string | null;
  pendente: boolean,
  tipoArquivo: string | null;
  iconeEnviada: string | null;
  motivosPendencia: string | null;
  motivoPendencia: string | null;
}

export interface ProposalResponseQuestion {
  id: number;
  descricao: string;
  chave: string;
  tipo_dado: string;
  resposta: string | null;
  pre_respostas: string | null; // stringified array
  etapa: ProposalResponseQuestionStepEnum | null;
}

export interface ProposalResponseModel {
  id: string;
  prazo_conclusao: string; // date string
  proposta: {
    numero: number;
    inicio_vigencia: string | null; // date string
    tipo_analise: number;
    pendente: boolean;
    segurado: {
      cpf_cnpj: string;
      nome_razao_social: string;
      nome_social: string;
      data_nascimento_fundacao: string; // date string
      fone_principal_ddd: string;
      fone_principal: string;
      email: string;
      principal_condutor: boolean;
      endereco: {
        cep: string;
        logradouro: string;
        bairro: string;
        numero: string;
        complemento: string | null;
        uf: string;
        cidade: string;
      }
    };
    veiculo: {
      marca: string;
      modelo: string;
      ano: number;
      zero_km: false,
      versao: string;
      placa: string;
      chassi: string;
      renavam: string;
      fipe: string | null;
    };
    condutor: {
      cpf: string;
      nome: string;
      data_nascimento: string; // date string
    };
    produto: {
      id: number,
      descricao: string,
      coberturas: null // ???
    }
  } | null,
  fotos: ProposalResponseResource[];
  documentos: ProposalResponseResource[];
  selfie: string | null;
  questionarios: ProposalResponseQuestion[];
  msg: string | null;
  enviada: boolean;
  reenviada: boolean;
  status?: ProposalStatus;
}
