<div class="self-vistoria-background"
     id="">
  <div class="self-vistoria-header-container">
    <div class="self-vistoria-header">
      <div class="self-vistoria-image-header"></div>
      <div class="self-vistoria-text-header">Instalação do rastreador</div>
      <div>
        <img src="{{ companyLogo }}"
             class="company-logo vistoria"/>
      </div>
    </div>

    <div class="container-stepper">
      <label class="label-stepper-separator"> Dados </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator active"> Pré instalação </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Fotos </label>
      <label class="label-stepper-separator"> > </label>
      <label class="label-stepper-separator"> Pós instalação </label>
    </div>
  </div>

  <div class="container-self-information">
    <div class="container-text-seft-photo">
      <p class="text-form">Agora, responda o questionário.</p>
    </div>

    <div class="container-selfie-questionario inline-flex-items-center">
      <ng-container *ngIf="proposal">
        <div *ngFor=" let question of questions; last as isLast; trackBy: utilService.questionTrackBy"
             class="confirmacao">
          <label class="layout-label-overall-label"
                 for="scales-{{ question.id }}">
            {{ question.description }}
          </label>

          <mat-radio-group *ngIf="question.dataType !== 'TEXT_AREA'; else textArea"
                           (change)="onQuestionChange($event, question)"
                           [(ngModel)]="question.answer"
                           id="scales-{{ question.id }}">
            <mat-radio-button *ngFor="let preAnswers of question.preAnswers"
                              [value]="preAnswers"
                              id="radio-{{ question.id }}-{{ preAnswers }}">
              {{ preAnswers }}
            </mat-radio-button>
          </mat-radio-group>

          <ng-template #textArea>
            <mat-form-field>
              <textarea matInput
                        (change)="onInputChange($event, question)"
                        [(ngModel)]="question.answer"
                        id="scales-{{ question.id }}"
                        class="layout-input-overall"></textarea>
            </mat-form-field>
          </ng-template>

          <mat-divider *ngIf="!isLast"></mat-divider>
        </div>
      </ng-container>
    </div>

    <button class="layout-btn-overall layout-btn-label-overall"
            [class.success]="canSubmitChecklist()"
            (click)="submit()"
            [disabled]="!canSubmitChecklist()">
      Avançar
    </button>
  </div>
</div>

<div class="footer">
  <mat-icon class="material-symbols-outlined">lock</mat-icon>
  <span>usebens.com.br</span>
</div>
