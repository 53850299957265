import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ProposalModel } from '../../../shared/models/proposal.model';
import { ProposalService } from '../../../shared/services/proposal.service';

@Component({
  selector: 'app-tracker-questionario-component',
  templateUrl: './tracker-questionario.component.html',
  styleUrls: ['./tracker-questionario.component.css'],
})

export class TrackerQuestionarioComponent {

  companyLogo = environment.company_logo;
  proposal: ProposalModel | undefined;

  constructor(
      private router: Router,
      private _proposalService: ProposalService,
  ) {
    this.proposal = this._proposalService.getProposal();
  }

  submit() {
    this.router.navigateByUrl('/self/checklist-tracker-pre');
  }
}
