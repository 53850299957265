import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-self-vistoria-sucesso-component',
  templateUrl: './self-vistoria-sucesso.component.html',
  styleUrls: ['./self-vistoria-sucesso.component.css'],
})
export class SelfVistoriaSucessoComponent {
  companyLogo = environment.company_logo;
  successIcon = environment.success_icon;
  successText = environment.success_text;
  successTitle = environment.success_title;

  constructor() {}
}
