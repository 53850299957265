import { Component, OnInit } from '@angular/core';
import { ProposalService } from '../../shared/services/proposal.service';
import { Router } from '@angular/router';
import { ProposalProductEnum } from '../../shared/models/proposal.model';

@Component({
  selector: 'app-self-component',
  templateUrl: './self.component.html',
  styleUrls: ['./self.component.css'],
})
export class SelfComponent implements OnInit {

  constructor(
      private _router: Router,
      private _proposalService: ProposalService,
  ) {
  }

  ngOnInit(): void {
    const proposal = this._proposalService.getProposal();

    if (proposal.proposal.product.id === ProposalProductEnum.TRACKER) {
      this._router.navigate(['/self/questionario-tracker']);
      return;
    }

    this._router.navigate(['/self/foto-veiculo']);
  }
}
