import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnauthorizedComponent } from './components/self-vistoria-falha/unauthorized.component';
import { SelfVistoriaSucessoComponent } from './components/self-vistoria-sucesso/self-vistoria-sucesso.component';
import { AllowedBrowsersGuard } from './shared/guards/allowed-browsers.guard';
import { BrowserNotAllowedComponent } from './components/browser-not-allowed/browser-not-allowed.component';
import { GeolocationGuard } from './shared/guards/geolocation.guard';
import { ClearProposalGuard } from './shared/guards/clear-proposal.guard';
import { SelfVistoriaAprovadoComponent } from './components/self-vistoria-aprovado/self-vistoria-aprovado.component';
import { ErroComponent } from './components/self-vistoria-erro/erro.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'self',
    pathMatch: 'full',
  },
  {
    path: 'login',
    redirectTo: 'self',
    pathMatch: 'full',
  },
  {
    path: 'self/login',
    redirectTo: 'self',
    pathMatch: 'full',
  },
  {
    path: 'foto-veiculo',
    redirectTo: 'self',
    pathMatch: 'full',
  },
  {
    path: 'questionario',
    redirectTo: 'self',
    pathMatch: 'full',
  },
  {
    path: 'questionario-tracker',
    redirectTo: 'self',
    pathMatch: 'full',
  },
  {
    path: 'self',
    canLoad: [GeolocationGuard, AllowedBrowsersGuard],
    loadChildren: () =>
      import('./components/self/self.module').then((m) => m.SelfModule),
  },
  {
    path: 'sucesso',
    canDeactivate: [ClearProposalGuard],
    component: SelfVistoriaSucessoComponent,
  },
  {
    path: 'reenvio',
    canDeactivate: [ClearProposalGuard],
    component: SelfVistoriaSucessoComponent,
  },
  {
    path: 'falha',
    component: UnauthorizedComponent,
  },
  {
    path: 'aprovado',
    component: SelfVistoriaAprovadoComponent,
  },
  {
    path: 'erro',
    component: ErroComponent,
  },
  {
    path: 'navegador-incompativel',
    component: BrowserNotAllowedComponent,
  },
  {
    path: '**',
    redirectTo: 'falha',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: true,
    }),
  ],
  providers: [GeolocationGuard, AllowedBrowsersGuard, ClearProposalGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
