import { Component } from '@angular/core';
import { environment } from '../environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { NavigationStart, Router } from '@angular/router';
import { AuthTokenService } from './shared/services/auth-token.service';
import { filter, map, take } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'usebens-vistoria-web-app';

  constructor(
      private _router: Router,
      private _authTokenService: AuthTokenService,
  ) {
    this.startDataDog();
    this._listenForAuthTokenOnFirstEntry();
  }

  startDataDog() {

    if (!environment.observe) {
      return;
    }

    datadogRum.init({
      applicationId: environment.DDapplicationId,
      clientToken: environment.DDclientToken,
      site: environment.DDsite,
      service: environment.DDservice,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      telemetrySampleRate: 100,
    });
    datadogRum.startSessionReplayRecording();
  }

  /**
   * This is mainly used when the user doesn't have all the permissions and
   * Angular redirects to the new URL with the access token.
   * */
  private _listenForAuthTokenOnFirstEntry() {
    this._router
        .events
        .pipe(
            filter((event: any) => event instanceof NavigationStart),
            map((event: NavigationStart) => {
              const authToken = this._getURLAuthToken(event.url);
              if (authToken) {
                return authToken;
              }
              return '';
            }),
            filter((authToken) => !!authToken),
            take(1),
        )
        .subscribe((authToken) => this._authTokenService.set(authToken));
  }

  private _getURLAuthToken(path: string) {
    const url = new URL(path, window.location.origin);
    const params = url.searchParams;
    const URLAuthToken = params.get('access');

    /**
     * this._authTokenService.get() is used to avoid memory leak when
     * navigation through the app.
     * */
    return URLAuthToken || this._authTokenService.get();
  }
}
